import { useState } from 'react';
import './CircularCheckbox.css'

interface ICheckboxParams {
    label?: string;
    onChecked: (isChecked: boolean) => void;
}

const CircularCheckbox: React.FC<ICheckboxParams> = ({ label, onChecked }) => {
    const [isChecked, setIsChecked] = useState(false);
    return (
        <div className="checkbox-wrapper">   
                <div className='round'>
                    <input type="checkbox" checked={isChecked} id="checkbox" onChange={() => { setIsChecked(!isChecked); onChecked(!isChecked) }} />
                    <label htmlFor="checkbox"></label>
                    {label && <span className='ms-4 ps-2'><b>{label}</b></span>}
                </div>
        </div>
    );
}
export default CircularCheckbox