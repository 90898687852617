import LogoText from "src/SharedComponents/LogoText/LogoText";
import { IDistributorInfo } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import { getChannelTypeIcon } from "src/Utils/Hooks/useGetDistributorInfo/SupportChannalTypes";
import { useMemo } from "react";

interface IPartnerSupportInfoParams {
    partnerDetails: IDistributorInfo;
}
const PartnerSupportInfo : React.FC<IPartnerSupportInfoParams> = ({partnerDetails}) => {

    var channalType = partnerDetails.supportContactDetail?.supportChannelType;
    var logoSrc = getChannelTypeIcon(channalType, "small");
    const show = useMemo(() => partnerDetails?.supportContactDetail?.contactNumber ||
    partnerDetails?.supportContactDetail?.email ||
    partnerDetails?.supportContactDetail?.supportChannelType ||
    partnerDetails?.supportContactDetail?.supportChannel,[partnerDetails?.supportContactDetail]);

    return (
    <>
    {show &&
     <>
          <div className="row">
                <div className="mb-2 col-9">
                    <b>Support Contact</b>
                </div>
            </div>
            {partnerDetails?.supportContactDetail?.email && <LogoText logoSrc={mailLogo} text={partnerDetails?.supportContactDetail?.email} />}
            {partnerDetails?.supportContactDetail?.contactNumber && <LogoText logoSrc={phoneLogo} text={partnerDetails?.supportContactDetail?.contactNumber} />}
            {partnerDetails?.supportContactDetail?.supportChannel && <LogoText logoSrc={logoSrc} text={partnerDetails?.supportContactDetail?.supportChannel} />}
     </>
    }
    </>)
}

export default PartnerSupportInfo;