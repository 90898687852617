import { Trans } from "react-i18next";

interface IRemoveDeviceInstructions {
    serialNumber:string;
    clinicName?:string;
}
const UnassignDeviceInstructions:React.FC<IRemoveDeviceInstructions> = ({serialNumber, clinicName}) => {
    return (
        <>
            <div className="mt-1">
                <p style={{ textAlign: 'center', marginBottom:'0px', fontSize:'14px', fontWeight:'400' }}>
                    <Trans i18nKey="UnassignDeviceInstruction"  values={{ serialNumber: serialNumber}} /></p>
                    
            </div>
        </>
    )
}
export default UnassignDeviceInstructions;