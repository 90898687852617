import React from 'react';
import './InfoBar.css';
export enum InfoMessageType {
  success = 'success',
  error = 'danger',
  warning = 'warning',
  none = 'noe'
}

export interface IInfobarContent {
  Message: string;
  MessageType: InfoMessageType;
}
export interface IInfoDetails extends IInfobarContent{
  closeClick: (val: boolean) => void;
}
const InfoBar: React.FC<IInfoDetails> = ({
  Message,
  MessageType,
  closeClick = () => {},
}) => {
  return (
    <>
      {
        <div className={`alert alert-${MessageType} info-bar-${MessageType}`} data-testid="infoBarTest">
          <span className={`close-${MessageType}`} onClick={() => closeClick(false)}>
            <i
              className="bi bi-x"
              style={{ right:'40px', position: 'absolute' }}
              data-testid="infoBarClose"
            ></i>
          </span>
          <span>{Message}</span>
        </div>
      }
    </>
  );
};
export default InfoBar;
