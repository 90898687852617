import { useEffect } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { useGetTechicalDocuments } from "./useGetTechicalDocuments";

export const useGetTechicalDocumentsWrapper = () => {
    const { data, loading, error } = useGetTechicalDocuments()
    const { setInfobar, setLoader, reset } = usePartnerInfo();
    useEffect(() => {
        if (error) {
            logger.logError(`Failed to get techinical documents.`)
            setInfobar({message: error, messageType: InfoMessageType.error});
        }
    }, [error, setInfobar])

    useEffect(()=> {
        setLoader(loading);
    }, [loading, setLoader])
    useEffect(() => {
        reset();
    },[reset])
    return {data}
}