import { useCallback, useMemo } from "react";
import { usePostRequest } from "../ApiWrappers/usePostRequest"
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
interface IDeviceManagement{
    clinicId:string,
    deviceId:string,
}
export const useDeviceService = () => {
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
    const {postRequest, reqestError, reqestLoading, reqestResponse} = usePostRequest();

    const assignToClinic = useCallback((deviceId:string, clinicId:string, distributorId:string) => {
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}/assign-device-from/distributor/${distributorId}`;
        logger.logInformation(`Request to assign device ${deviceId} to clinic ${clinicId} was made`)
        let req:IDeviceManagement = {
            clinicId: clinicId,
            deviceId:deviceId
        }
        postRequest<IDeviceManagement>(req,requestUrl)
    },[baseUrl, postRequest]) 

    const removefromClinic = useCallback((deviceId:string, clinicId:string, distributorId:string) => {
        let req:IDeviceManagement = {
            clinicId: clinicId,
            deviceId: deviceId
        }
        logger.logInformation(`Request to Unassign device ${deviceId} from clinic ${clinicId} was made`)
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}/remove-device-from/distributor/${distributorId}`;
        postRequest<IDeviceManagement>(req,requestUrl)
    },[baseUrl, postRequest]) 
    return { assignToClinic, removefromClinic, reqestResponse, reqestLoading, reqestError };
}