import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { usePutRequest } from "../ApiWrappers/usePutRequest";

export interface IEditPartnerInfoRequest {
    name: string,
    contactNumber?: string,
    email?: string,
    streetAddress?: string,
    city?:string,
    country?:string,
    zipCode?:string,
    supportEmail?:string,
    supportContactNumber?:string,
    supportMessagingChannel?:string,
    supportMessagingChannelType?:string,
}

export const UseEditPartnerInfo = () => {
    const { putRequest, reqestError, reqestLoading, reqestResponse } = usePutRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const editPartnerInfo = useCallback((req: IEditPartnerInfoRequest, partnerId: string) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${partnerId}`;
        logger.logInformation(`Request to edit partner ${partnerId} with request data ${JSON.stringify(req)} was made`)
        putRequest<IEditPartnerInfoRequest>(req, requestUrl);
    }, [baseUrl, putRequest]);

    return { editPartnerInfo, reqestResponse, reqestLoading, reqestError};
};
