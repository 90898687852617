import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useDeleteRequest } from "../ApiWrappers/useDeleteRequest";

export const useDeleteClinic = () => {
    const { deleteRequest, requestResponse, requestError, requestLoading } = useDeleteRequest();
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);
    const removeClinic = useCallback((clinicId: string) => {
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}`;
        deleteRequest(requestUrl);
    }, [baseUrl, deleteRequest]);

    return { requestResponse, requestError, requestLoading, removeClinic };
}
