import React from "react";
import { useTranslation } from "react-i18next";
import { IInviteUserRequest } from "src/Utils/Hooks/UserService/useGetUserService";
import { useGetUserServiceWrapper } from "src/Utils/Hooks/UserService/useGetUserServiceWrapper";

interface ResendInvitationProps {
  email: string;
  distributorId?: string;
}

const ResendInvitation: React.FC<ResendInvitationProps> = ({ email, distributorId }) => {
  const { reinviteUser } = useGetUserServiceWrapper();
  const { t } = useTranslation();
  const request : IInviteUserRequest = {
    emailId: email,
    distributorId: distributorId
  }
  return (
    <div className="dropdown-item" onClick={() => reinviteUser(request)}>
        {t('ResendInvitation')}
    </div>
  );
};

export default ResendInvitation;
