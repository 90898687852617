import whatsAppIcon from 'src/images/whatsapp_20_20.png';
import weChatIcon from 'src/images/wechat_20_20.png';
import instagramIcon from 'src/images/instagram_20_20.png';
import facebookIcon from 'src/images/facebook_20_20.png';
import otherIcon from 'src/images/other_20_20.png';


import whatsappIconSmall from 'src/images/whatsapp_16_16.png';
import wechatIconSmall from 'src/images/wechat_16_16.png';
import instagramIconSmall from 'src/images/instagram_16_16.png';
import facebookIconSmall from 'src/images/facebook_16_16.png';
import otherIconSmall from 'src/images/other_16_16.png';

export const WhatsApp = "WhatsApp"
export const WeChat = "WeChat"
export const Facebook = "Facebook"
export const Instagram = "Instagram"
export const Other = "Other"

const supportChannelTypeIcons: Record<string, { large: string; small: string }> = {
    WhatsApp: { large: whatsAppIcon, small: whatsappIconSmall },
    WeChat: { large: weChatIcon, small: wechatIconSmall },
    Instagram: { large: instagramIcon, small: instagramIconSmall },
    Facebook: { large: facebookIcon, small: facebookIconSmall },
    Other: { large: otherIcon, small: otherIconSmall }
};

export const getChannelTypeIcon = (key: string | undefined, size: 'large' | 'small' = 'large'): string => {
    const channel = supportChannelTypeIcons[key ?? 'Other'];
    return channel[size] ?? otherIcon; 
};