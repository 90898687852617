import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "src/SharedComponents/Button/Button"
import ErrorMessage from "src/SharedComponents/ErrorMessage/ErrorMessage"
import InputBoxWithLabel from "src/SharedComponents/InputBoxWithLabel/InputBoxWithLabel"
import PhoneInputBox from "src/SharedComponents/PhoneInputBox/PhoneInputBox"
import { IDistributorInfo } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo"
import './EditPartnerData.css';
import { useEditPartnerInfoWrapper } from "src/Utils/Hooks/UseEditPartnerInfo/useEditPartnerInfoWrapper"
import MessagingChannel from "../MessagingChannel/MessagingChannel"

interface IEditClinicDataParams {
    partnerDetails: IDistributorInfo;
    onClose: () => void;
}

const EditPartenerData:React.FC<IEditClinicDataParams> = ({onClose, partnerDetails}) => {
    const { t } = useTranslation();
    const {editPartnerInfoDetails, distributorDetails} = useEditPartnerInfoWrapper();
    const [validationErrors, setValidationErrors] = useState({
        name: ''
    });

    const [editedPartnerDetails, setEditedPartnerDetails] = useState({
            name: partnerDetails.name,
            contactNumber: partnerDetails.contact,
            email: partnerDetails.email,
            streetAddress: partnerDetails.streetAddress,
            city:partnerDetails.city,
            country:partnerDetails.country,
            zipCode:partnerDetails.zipCode,
            supportEmail:partnerDetails.supportContactDetail.email,
            supportContactNumber:partnerDetails.supportContactDetail.contactNumber,
            supportMessagingChannel:partnerDetails.supportContactDetail.supportChannel,
            supportMessagingChannelType:partnerDetails.supportContactDetail.supportChannelType,
        }
    )

    const myRef = useRef(null);
    const [enableSave, setEnableSave] = useState<boolean>(false);

    useEffect(()=> {
        let infoChanged = (editedPartnerDetails.name !== partnerDetails.name) || 
                          (editedPartnerDetails.streetAddress !== partnerDetails.streetAddress) || 
                          (editedPartnerDetails.email !== partnerDetails.email) ||
                          (editedPartnerDetails.contactNumber !== partnerDetails.contact) ||
                          (editedPartnerDetails.zipCode !== partnerDetails.zipCode) ||
                          (editedPartnerDetails.city !== partnerDetails.city) ||
                          (editedPartnerDetails.country !== partnerDetails.country) ||
                          (editedPartnerDetails.supportEmail !== partnerDetails.supportContactDetail.email)||
                          (editedPartnerDetails.supportContactNumber !== partnerDetails.supportContactDetail.contactNumber) ||
                          (editedPartnerDetails.supportMessagingChannel !== partnerDetails.supportContactDetail.supportChannel) ||
                          (editedPartnerDetails.supportMessagingChannelType !== partnerDetails.supportContactDetail.supportChannelType);
        setEnableSave(infoChanged);
    }, [editedPartnerDetails, partnerDetails])

    useEffect(() => {
        if(distributorDetails) {
           onClose()
        }
    }, [distributorDetails, onClose])
    
    const isValidRequest = () => {
        const errors: any = {};
        let isValid = true;
        setValidationErrors(errors);
        if (editedPartnerDetails.name === '') {
            errors.name = t('NameRequired');
            isValid = false;
        }
        setValidationErrors(errors);

        return isValid;
    }
    
    const onEditPartnerInfo = () => {
        if (isValidRequest()) {
            editPartnerInfoDetails(editedPartnerDetails, partnerDetails.distributorId);
        }
    }
    
    return(
        <>
         <div className="row mt-2" ref={myRef}>
                <InputBoxWithLabel
                    value={editedPartnerDetails.name}
                    onChange={(e) => {  setEditedPartnerDetails({ ...editedPartnerDetails, name: e }) } }
                    label={t('PartnerName')} />
                <ErrorMessage message={validationErrors.name}></ErrorMessage>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, email: e }) }}
                        label={t('PartnerEmailAddress')}
                        value={editedPartnerDetails.email} />
                </div>
                <div className="col-6">
                    <div className="label mb-2">
                        {t('PartnerPhoneNumber')}
                    </div>
                    <PhoneInputBox
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, contactNumber: e }) }}
                        value={editedPartnerDetails.contactNumber} />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, zipCode: e }) }}
                        label={t('ZipCode')}
                        value={editedPartnerDetails.zipCode} />
                </div>
                <div className="col-6">
                   <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, city: e }) }}
                        label={t('City')}
                        value={editedPartnerDetails.city} />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                   <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, streetAddress: e }) }}
                        label={t('StreetAddress')}
                        value={editedPartnerDetails.streetAddress} />
                </div>
                <div className="col-6">
                    <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, country: e }) }}
                        label={t('Country')}
                        value={editedPartnerDetails.country} />
                </div>
            </div>

            <div className="row mt-5">
                 <b>{t('SupportContact')}</b>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <InputBoxWithLabel
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, supportEmail: e}) }}
                        label={t('SupportEmail')}
                        value={editedPartnerDetails.supportEmail} />
                </div>
                <div className="col-6">
                    <div className="label mb-2">
                        {t('SupportContactNumber')}
                    </div>
                    <PhoneInputBox
                        onChange={(e) => { setEditedPartnerDetails({ ...editedPartnerDetails, supportContactNumber: e}) }}
                        value={editedPartnerDetails.supportContactNumber} />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                  <MessagingChannel 
                     supportChannel={editedPartnerDetails.supportMessagingChannel} 
                     supportChannelType={editedPartnerDetails.supportMessagingChannelType}
                     onChannalChange={(channal) => { setEditedPartnerDetails({ ...editedPartnerDetails, supportMessagingChannel: channal}) }}
                     onChannalTypeChange={(type) => { setEditedPartnerDetails({ ...editedPartnerDetails, supportMessagingChannelType: type}) }}
                     />
                </div>
               
            </div>
            <div className="row d-flex justify-content-center">
                <div className="button-container mt-5 mb-4">
                    <div className="button-wrapper">
                        <Button
                            backgroundColor='none'
                            color='#A8927D'
                            testId='cancel'
                            onClick={() => { onClose() }}
                            text={t('Cancel')}
                            width='141px'
                            height='48px'
                            borderRadius='24px'
                            borderColor="#D0D0D0"
                        />
                    </div>
                    <div className="button-wrapper">
                        <Button
                            testId='save'
                            onClick={() => { onEditPartnerInfo() }}
                            text={t('Save')}
                            width='141px'
                            disable={!enableSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditPartenerData;