import { ITechincalDocument } from "src/Utils/Hooks/Downloads/useGetTechicalDocuments";
import DocumentsDetails from "../Documents/DocumentsDetails";
import markeingMaterial from 'src/images/additional-marketing-material.png';
import { useEffect, useState } from "react";
const MarketingMaterialDocuments = () => {

    const [data, setData] = useState<ITechincalDocument[]>();

    useEffect(() => {
        var marketing_data:ITechincalDocument = {
            displayName: 'Additional Marketing Material',
            previewData: markeingMaterial,
            sasToken: 'https://bynder.hexagon.com/web/86e99b35d8feffb/aura-3d-imaging-system-customer-collateral',
        }
        setData([marketing_data])
    },[])
    return (
        <>
            {data &&
                <>
                    <hr className='line mt-4'></hr>
                    <DocumentsDetails data={data} title="Marketing Material" id="marketing-material-doc" />
                </>
            }
        </>
    )
}
export default MarketingMaterialDocuments;