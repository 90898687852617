import { useState } from 'react';
import './TextBox.css'
import { useTranslation } from 'react-i18next';
interface ITextBox {
  label: string;
  value?: string;
  onChange: (text: string) => void;
}
const TextBox: React.FC<ITextBox> = ({ label, onChange, value }) => {
  let initialVal = value ? value : '';
  const [val, setVal] = useState(initialVal);
  const { t } = useTranslation();
  return (
    <>
      <div className="label mb-2">{t(label)}</div>
      <textarea
        className="textBox"
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
          onChange(e.target.value);
        }}
      />
    </>
  )
}
export default TextBox