import { useGetDistributorInfoWrapper } from "src/Utils/Hooks/useGetDistributorInfo/userGetDistributorInfoWrapper";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { useEffect, useState } from "react";
import ButtonWithIcon from "src/SharedComponents/ButtonWithIcon/ButtonWithIcon";
import ClinicHandler from "./ClinicHandler/ClinicHandler";
import { IClinic } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import ClinicInfo from "./ClinicInfo/ClinicInfo";
import CreateClinic from "./CreateClinic/CreateClinic";
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter"
import { useTranslation } from "react-i18next";
import { usePartnerInfo } from "src/Utils/Hooks/usePartnerInfoContext";
import SearchBar from "src/SharedComponents/SearchBar/SearchBar";

const Clinics: React.FC = () => {
    const [showCreateClinicDialog, setShowCreateClinicDialog] = useState<boolean>(false);
    const { getDistributorInfo } = useGetDistributorInfoWrapper();
    const { distributorDetails } = usePartnerInfo();
    const [showClinicInfo, setShowClinicInfo] = useState<boolean>(false);
    const [selectedClinic, setSelectedClinic] = useState<IClinic>();
    const [searchText, setSearchText] = useState<string>('');
    const [distributorClinics, setDistributorClinics] = useState<IClinic[]|undefined>(distributorDetails?.clinics);
    const { t } = useTranslation();
    useEffect(() => {
        if (distributorDetails && selectedClinic) {
            let clinicId = selectedClinic.clinicId;
            setSelectedClinic(distributorDetails.clinics.find(clinic => clinic.clinicId === clinicId));
        }
    }, [distributorDetails, selectedClinic, setSelectedClinic])

    useEffect(() => {
        if(distributorDetails?.clinics) {
                if(searchText !== '') {
                        var searchedClinics = distributorDetails?.clinics.filter(clinic => 
                            (clinic.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false))
                            setDistributorClinics(searchedClinics)
                    }
                    else
                    {
                        setDistributorClinics(distributorDetails?.clinics)
                    }
        }
    }, [searchText, setDistributorClinics, distributorDetails])
    return (
        <>
            {showClinicInfo ? (selectedClinic &&
                // Render the ClinicInfo component when showClinicInfo is true
                <ClinicInfo clinic={selectedClinic} getClinicInfo={getDistributorInfo} onClose={() => { setShowClinicInfo(false); getDistributorInfo() }} />
            ) : (
                <div>
                    <div  className="row">
                    <div className="col d-flex align-items-center w-100">
                    <SearchBar onChange={(text:string) => {setSearchText(text) }} />
                    </div>
                    <div className="col-auto d-flex justify-content-end">
                        <ButtonWithIcon
                            backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                            onClick={() => { setShowCreateClinicDialog(true) }}
                            label={t('NewClinic')}
                            icon='bi bi-plus-lg'
                            testId='newUser'
                            color='white'
                        />
                    </div>
                    </div>
                    {
                        showCreateClinicDialog &&
                        <CreateClinic
                            onClose={() => setShowCreateClinicDialog(false)}
                            updateClinics={getDistributorInfo}
                        />
                    }
                    <div className='row mt-4'>
                        <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable} px-4`}>
                            {distributorDetails &&
                                <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                                    <thead className={`${tableStyles.tableHead}`}>
                                        <tr key={'header'}>
                                            <th className={tableStyles.heading}>{t('ClinicName')}</th>
                                            <th className={tableStyles.heading}>{t('SetUpDate')}</th>
                                            <th className={tableStyles.heading}>{t('NoOfDevices')}</th>
                                            <th className={tableStyles.heading}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distributorClinics?.sort((a, b) => (a.name.localeCompare(b.name))).map((clinic) => (
                                            <tr key={clinic.clinicId}>
                                                <td className={tableStyles.data} style={{ fontWeight: 600 }}>
                                                    {clinic.clinicId ? clinic.name : ''}
                                                </td>
                                                <td className={tableStyles.data}>{formatDateString(clinic.setUpDate)}</td>
                                                <td className={tableStyles.data}>{clinic.devicesCount}</td>
                                                <td className={tableStyles.data} style={{ display: 'flex', justifyContent: 'end' }}>
                                                    <ClinicHandler
                                                        clinic={clinic}
                                                        updateClinics={getDistributorInfo}
                                                        onOpenClinicClick={(clinic) => {
                                                            setSelectedClinic(clinic);
                                                            setShowClinicInfo(true);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>
                </div>
            )}
        </>


    )
}
export default Clinics;