import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../ApiWrappers/usePostRequest";
import { logger } from "src/Utils/LoggerService/LoggerService";

export interface ICreateClinicRequest {
    name: string,
    email: string,
    contactNumber: string,
    address: string
    clinicAdminemail:string
}

export interface IClinicInfo {
    clinicId: string,
    name: string,
    locations: string[],
    devices: string[],
    email: string,
    contactNumber: string,
    address: string,
    distributorDetail: string
}


export const useCreateClinic = () => {
    const { postRequest, reqestError, reqestLoading, reqestResponse } = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const createNewClinic = useCallback((req: ICreateClinicRequest) => {
        const requestUrl = `${baseUrl}api/v1/clinic`;
        logger.logInformation(`Request to create clinic with request data ${JSON.stringify(req)} was made`)
        postRequest<ICreateClinicRequest>(req, requestUrl);
    }, [baseUrl, postRequest]);

    return { createNewClinic, reqestResponse, reqestLoading, reqestError};
};
