import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../ApiWrappers/usePostRequest";

export const useReinviteClinicAdmin = () => {
    const { postRequest, reqestError, reqestLoading, reqestResponse } = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const reinviteClinicAdmin = useCallback((email: string, clinicId: string) => {
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}/user/re-invite`;
        postRequest<string>(email, requestUrl)
    }, [baseUrl, postRequest])

    return { reinviteClinicAdmin, reqestResponse, reqestLoading, reqestError };
};
