import { IClinic } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import MoreOptionsMenu from "../../MoreOptions/MoreOptions"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DeleteClinic from "../DeleteClinic/DeleteClinic";

interface IClinicHandler {
    clinic: IClinic;
    updateClinics: () => void;
    onOpenClinicClick: (clinic: IClinic) => void;
}
const ClinicHandler: React.FC<IClinicHandler> = ({ clinic, onOpenClinicClick, updateClinics }) => {
    const { t } = useTranslation();
    const [deleteClinic, setDeleteClinic] = useState<boolean>(false);
    return (
        <>
        {
            deleteClinic && <DeleteClinic clinicId={clinic.clinicId} clinicName={clinic.name} updateClinics={updateClinics} onClose={() => setDeleteClinic(false)}/>
        }
            <div className="px-2">
                <MoreOptionsMenu>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><div className="dropdown-item" key="1" onClick={() => onOpenClinicClick(clinic)}>{t('OpenClinic')}</div></li>
                        <li><div className="dropdown-item" style={{color: "#FF0F00"}} key="1" onClick={() => setDeleteClinic(true)}>{t('DeleteClinic')}</div></li>
                    </ul>
                </MoreOptionsMenu>
            </div>
        </>
    )
}
export default ClinicHandler