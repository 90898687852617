import axios from "axios";
import { useCallback, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useGetRequest = <T extends Partial<T>>(): [((scope:string, requestUrl:string) => void), T | undefined , (boolean), (string)] => {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const getData = useCallback((scope:string, requestUrl:string) => {
      setLoading(true);
      setError('');

      AuthConfig.getToken(scope).then(
          (success) => {
            const headers = {
              Authorization: `Bearer ${success}`,
            };
            axios
              .get(requestUrl, { headers })
              .then(
                (response) => {
                  setData(response.data);
                  const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                  logger.logInformation(`Get data details for ${requestUrl}: ${status}.`);
                  setError('');
                },
  
                (error) => {
                  const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                  logger.logError(`Error occured to fetch details for ${requestUrl}: ${status}.`)
                  if(error.message && error.message !== '') {
                      setError(error.message);
                  } else {
                      setError(error.response.data);
                  }
                  setData(undefined);
                }
              )
              .finally(() => {
                  setLoading(false);
              });
          },
          (error) => {
              setLoading(false);
              setError(error);
          }
        );
      }
  , []);

    return [
      getData,
      data,
      loading,
      error
  ];
}