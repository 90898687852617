import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { useReinviteClinicAdmin } from "./useReInviteClinicAdmin";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";

export const useReinviteClinicAdminWrapper = () => {
    const { reqestResponse, reqestLoading, reqestError, reinviteClinicAdmin } = useReinviteClinicAdmin();
    const { setInfobar, setLoader } = usePartnerInfo();
    const adminDetails = useRef<{
        email: string;
        clinicId: string;
    }>({ email: '', clinicId: '' });
    useEffect(() => {
        if (reqestError !== '') {
            logger.logError(`Error occur while reinviting admin to clinic ${reqestError}. EmailId is ${adminDetails.current.email}, clinicId is ${adminDetails.current.clinicId}.`);
            setInfobar({ message: reqestError, messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
        if (reqestResponse) {
            logger.logInformation(`Admin is reinvited successfully. EmailId is ${adminDetails.current.email}, clinicId is ${adminDetails.current.clinicId}.`);
            setInfobar({ message: 'Admin is reinvited successfully.', messageType: InfoMessageType.success })
        }
    }, [reqestResponse, setInfobar])
    useEffect(() => {
        setLoader(reqestLoading)
    }, [reqestLoading, setLoader])

    const reinviteAdmin = useCallback((email: string, clinicId: string) => {
        logger.logInformation(`Request to reinvite admin with email ${email} and clinic id ${clinicId} was made`)
        adminDetails.current.email = email;
        adminDetails.current.clinicId = clinicId;
        reinviteAdmin(email, clinicId);
    },[]) 

    return {reqestResponse, reqestError, reinviteClinicAdmin}
}