import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import './AppLayout.css';
import React, { useEffect, useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { InfoContext, InfobarDetails } from 'src/Utils/Hooks/usePartnerInfoContext';
import { InfoMessageType } from 'src/SharedComponents/InfoBar/InfoBar';
import { IDistributorInfo } from 'src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo';
import MainContent from './MainContent';

const MainLayout: React.FC = () => {
    const [show, setShow] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [distributorDetails, setDistributorInfo] = useState<IDistributorInfo>();
    const [infoMessageDetails, setInfoMessageDetails] = useState<InfobarDetails>({message:'', messageType:InfoMessageType.none})
    const { login, error } = useMsalAuthentication(InteractionType.Redirect);
    const isAuthenticated = useIsAuthenticated();
    
    useEffect(() => {
        if (error) {
            login(InteractionType.Popup);
        }
    }, [error, login]);

    return (
        <>
            {isAuthenticated && 
                <>
                <InfoContext.Provider value={{show,  setShow, infoMessageDetails, setInfoMessageDetails, loader, setLoader, distributorDetails, setDistributorInfo}}>
                    <MainContent />
                </InfoContext.Provider>  
                </>
            }
        </>
    );
};
export default React.memo(MainLayout);
