import LogoText from "src/SharedComponents/LogoText/LogoText";
import { IDistributorInfo } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import MoreOptionsMenu from "../../MoreOptions/MoreOptions";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface IPartnerInfoParams {
    partnerDetails: IDistributorInfo;
    onEditPartnerClick: () => void;
}

const PartnerInfo: React.FC<IPartnerInfoParams> = ({ partnerDetails, onEditPartnerClick }) => {
    const { t } = useTranslation();
   
    const address = useMemo(() => { 
          const addressParts = [partnerDetails.streetAddress, partnerDetails.city, partnerDetails.zipCode, partnerDetails.country];
          return addressParts.filter(part => part).join(", ")
        }, [partnerDetails]);

    return (
        <>
            <div className="row">
                <div className="mb-2 col-9">
                    <b>{partnerDetails?.name}</b>
                </div>
                <div className="col-3 d-flex justify-content-end" style={{ height: '32px' }}>
                    <MoreOptionsMenu>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><div className="dropdown-item" key="1" id='edit-clinic-data' onClick={onEditPartnerClick} >{t('EditPartenerData')}</div></li>
                        </ul>
                    </MoreOptionsMenu>
                </div>
            </div>
            {partnerDetails?.email  && <LogoText logoSrc={mailLogo} text={partnerDetails?.email} />}
            {partnerDetails?.contact && <LogoText logoSrc={phoneLogo} text={partnerDetails?.contact} /> }
            {address && <LogoText logoSrc={mapPinLogo} text={address} />}
            
        </>
    )
}
export default PartnerInfo;