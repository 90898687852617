import { useState, useMemo, useCallback } from 'react';

export interface SortConfig<T> {
  key: keyof T;
  direction: 'ascending' | 'descending';
}

const useSortableData = <T extends object>(items: T[], config: SortConfig<T> | null = null) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T> | null>(config);

  const sortedItems = useMemo(() => {
    if (!sortConfig) return items;
    
    const sortedArray = [...items];
    sortedArray.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Convert values to strings for case-insensitive comparison
      const aStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
      const bStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

      if (aStr < bStr) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aStr > bStr) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedArray;
  }, [items, sortConfig]);

  const requestSort = useCallback((key: keyof T) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  },[sortConfig]);

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
