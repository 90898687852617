import DocumentsDetails from "../Documents/DocumentsDetails";
import { useGetTechicalDocumentsWrapper } from "src/Utils/Hooks/Downloads/useGetTechnicalDocumentsWrapper";

const TechnicalDocuments = () => {
    const { data } = useGetTechicalDocumentsWrapper();
    return (
        <>
            {data &&
                <>
                    <DocumentsDetails data={data} title="Technical Documentation" id="tech-doc" />
                </>
            }
        </>
    )
}
export default TechnicalDocuments;