import { useState, useEffect } from 'react';

interface Item {
  [key: string]: any;
};

export const useSearch = <T extends Item>(items: T[], searchTerm: string, searchKeys: (keyof T)[]): T[] => {
  const [filteredItems, setFilteredItems] = useState<T[]>([]);

  useEffect(() => {
   if (!searchTerm) {
      setFilteredItems(items);
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const newFilteredItems = items.filter(item =>
        searchKeys.some(key =>
          item[key]?.toString().toLowerCase().includes(lowercasedSearchTerm) ?? false
        )
      );
      setFilteredItems(newFilteredItems);
    }
  }, [items, searchTerm, searchKeys]);

  return filteredItems;
};