import deviceIcon from 'src/images/device.png';
import Button from "src/SharedComponents/Button/Button";
import DeviceGrid from '../DeviceGrid/DeviceGrid';
import BoldText from 'src/SharedComponents/BoldText/BoldTest';
import { useState } from 'react';
import { IDevice } from 'src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo';
import UnassignDevice from '../UnassignDevice/UnassignDevice';
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter";
import { useTranslation } from 'react-i18next';

export interface IAssgnedDevice {
    distributorId:string, 
    device: IDevice;
    updateDevices:() => void
}
const AssignedDevice:React.FC<IAssgnedDevice> = ({distributorId,device, updateDevices}) => {
    const [showUnassignDeviceConfirmDialog, setshowUnassignDeviceConfirmDialog] = useState<boolean>(false);
    const { t } = useTranslation();
    return (
        <>
        {showUnassignDeviceConfirmDialog &&
                <UnassignDevice
                    closeDialog={() => setshowUnassignDeviceConfirmDialog(false)}
                    deviceId={device.deviceId}
                    clinicId={device.clinicId}
                    clinicName={device.clinicName}
                    serialNumber={device.serialNumber}
                    distributorId={distributorId}
                    updateDevices={updateDevices}
                />
            }
        <DeviceGrid>
            <div className='col-1 d-flex justify-content-center'>
                <img src={deviceIcon} alt="device" style={{ width: '40px', height: '40px' }} />
            </div>
            <div className='col-3' style={{ marginLeft: '19px' }}>
                <BoldText text={device.serialNumber}/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('SerialNumber')}</div>
            </div>

            <div className='col-3' style={{ marginLeft: '19px' }}>
                <BoldText text={device.clinicName}/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('Owner')}</div>
            </div>

            <div className='col-2' style={{ marginLeft: '19px' }}>
                <BoldText text={ formatDateString(device.assignedDate) }/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('DateOfAssignment')}</div>
            </div>
            <div className='col-2' style={{ marginLeft: '19px' }}>
                <Button
                    backgroundColor="none"
                    color="#A8927D"
                    text={t("Unassign")}
                    borderColor="#A8927D29"
                    fontSize="12px"
                    width='160px'
                    borderStyle='solid'
                    borderWidth='2px'
                    fontWeight='600'
                    onClick={() => { if(device.clinicId) { setshowUnassignDeviceConfirmDialog(true)}}}
                />
            </div>
            </DeviceGrid>
        </>
    )
}
export default AssignedDevice;