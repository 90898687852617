import GridView from "src/SharedComponents/GridView/GridView";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import { useState } from "react";
import LogoText from "../../SharedComponents/LogoText/LogoText";
import { useTranslation } from "react-i18next";
import PartnerInfo from "./PartnerInfo/PartnerInfo";
import EditPartenerData from "./EditPartnerData/EditPartnerData";
import { usePartnerInfo } from "src/Utils/Hooks/usePartnerInfoContext";
import PartnerSupportInfo from "./PartnerSupportInfo";

const Contact: React.FC = () => {
    const { distributorDetails } = usePartnerInfo();
    const [enableEditPartnerData, setEnableEditPartnerData] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            {distributorDetails &&
                <GridView title={t("ContactInfo")}>
                    <div className="d-flex flex-column">
                       {
                       !enableEditPartnerData ?
                        (<><PartnerInfo
                                    partnerDetails={distributorDetails}
                                    onEditPartnerClick={() => setEnableEditPartnerData(true)} />
                                    <PartnerSupportInfo partnerDetails={ distributorDetails } />
                        </>
                    )
                       : 
                       <EditPartenerData partnerDetails={distributorDetails} onClose={() => setEnableEditPartnerData(false)}/>
                    }
                    </div>
                </GridView>
            }
            <GridView title="Hexagon Aura Reality AG">
                <div className="d-flex flex-column">
                    <LogoText logoSrc={mailLogo} text='office@aurareality.com' />
                    <LogoText logoSrc={phoneLogo} text='+41 71 727 41 41' />
                    <LogoText logoSrc={mapPinLogo} text='Heinrich-Wild-Strasse 201, 9435 Heerbrugg, Switzerland' />
                </div>
            </GridView>
        </>
    )
}
export default Contact;