import { useState } from 'react';
import './InputBox.css'
interface IInputBox {
    width?: string;
    height?: string;
    value?: string;
    readOnly?: boolean;
    backGround?:string;
    onChange: (text: string) => void;
}
const InputBox: React.FC<IInputBox> = ({ width, height, value, readOnly, backGround, onChange }) => {
    let initialVal = value ? value : '';
    const [val, setVal] = useState(initialVal);
    return (
        <input
            type="text"
            className="form-control input-box"
            data-testid="input"
            style={{ width: width, height: height, background:backGround }}
            value={val}
            onChange={(e) => {
                setVal(e.target.value);
                onChange(e.target.value);
            }}
            readOnly={readOnly ? true : false}
        />

    )
}
export default InputBox