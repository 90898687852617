import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../ApiWrappers/usePostRequest";

export interface IInviteUserRequest {
    emailId: string,
    distributorId?: string
}
export const useUserService = () => {
    const {postRequest, reqestError, reqestLoading, reqestResponse} = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);

    const addUser = useCallback((req: IInviteUserRequest) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${req.distributorId}/invite-user`;
        const requestData = {
            email: req.emailId
        };          
        postRequest(requestData,requestUrl)
    },[baseUrl, postRequest]) 

    const reInviteUser = useCallback((req: IInviteUserRequest) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${req.distributorId}/re-invite-user`;
        postRequest<string>(req.emailId,requestUrl)
    },[baseUrl, postRequest]) 

    return { addUser, reInviteUser, reqestResponse, reqestLoading, reqestError };
}