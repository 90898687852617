import React from 'react';
import { memo } from "react";
import divStyles from 'src/Utils/CssModules/div.module.css';

interface IDropDownItemProps {
  logoSrc: string;
  text: string;
  color?: string;
  textColor?:string;
  disabled?:boolean;

  OnChange: (item:string) => void;
}

const DropDownItem: React.FC<IDropDownItemProps> = ({ logoSrc, text, textColor, color, disabled, OnChange }) => {
  const iconColor = color || '#A8927D';
  const txtColor = textColor || '#A8927D';
  var disable = disabled ? divStyles.disabled : ''
  return (
    <div className={`mb-3 ${disable}`} style={{cursor:'pointer'}}  onClick={() => {OnChange(text)}} >
      <i style={{ color: iconColor }}>
        <img src={logoSrc} alt="Logo" />
      </i>
      <span style={{ paddingLeft: '10px', color: txtColor, fontSize:'14px' }}>{text}</span>
    </div>
  );
};

export default memo(DropDownItem);
