import {ApplicationInsights, ITelemetryItem, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { Config } from '../ConfigService/LoadConfig';

let reactPlugin :ReactPlugin ;
let appInsights :ApplicationInsights;
let minLogLevel: number ;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (): void => {
      const env = Config.getEnvironment();
       if(env === "local")
       {
          return
       }
        const settings = Config.GetConfigData().ApplicationInsights;
        const browserHistory = createBrowserHistory();

        // MinLogLevel allows you to control which log messages are sent to Application Insights based on their severity level.
        minLogLevel = settings.minLevel;
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!settings.key) {
            throw new Error('Instrumentation key not provided')
        }
       
        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: settings.key,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        // https://github.com/Azure/react-appinsights/issues/84

        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
          envelope.tags = envelope.tags || [];
          envelope.tags.push({ "ai.cloud.role": `distributor-portal-euw-${env}` });
        });

        appInsights.loadAppInsights();
    };

    const logTrace = (message: string, logLevel:number) => {
        
        //  Filter out less critical log messages, ensuring that only more important log entries are sent to Application Insights.
        if (minLogLevel <= logLevel ) {
              appInsights.trackTrace({ message: message, severityLevel: logLevel});
        }
      }
    const logException = (exception: string) => {
        let error = new Error(exception)
        appInsights.trackException({ exception: error});
      }
    const logEvent = (message: string) =>  {
        appInsights.trackEvent({name: message});
      }
    const logInformation = (message: string) => {
        logTrace(message, SeverityLevel.Information);
      }
    
    const logWarning = (message: string) => {
        logTrace(message, SeverityLevel.Warning);
      }
    const logError = (message: string) => {
        logTrace(message, SeverityLevel.Error);
      }
    const logDebug = (message: string) => {
        logTrace(message, SeverityLevel.Verbose);
      }
    const logCritical = (message: string) => {
        logTrace(message, SeverityLevel.Critical);
      }

    return {reactPlugin, 
        appInsights, 
        initialize, 
        logInformation, 
        logWarning, 
        logError, 
        logDebug, 
        logCritical,
        logException,
        logEvent
    };
};

export const logger = createTelemetryService();
