
import './Devices.css'
import UnassignedDevice from "./UnassignedDevices/UnassignedDevices";
import { useGetDistributorInfoWrapper } from "src/Utils/Hooks/useGetDistributorInfo/userGetDistributorInfoWrapper";

import BoldText from 'src/SharedComponents/BoldText/BoldTest';
import AssignedDevice from './AssignedDevice/AssignedDevice';
import { useTranslation } from 'react-i18next';
import { usePartnerInfo } from 'src/Utils/Hooks/usePartnerInfoContext';
import SearchBar from 'src/SharedComponents/SearchBar/SearchBar';
import { useEffect, useState } from 'react';
import { IDevice } from 'src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo';

const Devices: React.FC = () => {
    const { getDistributorInfo } = useGetDistributorInfoWrapper();
    const { distributorDetails } = usePartnerInfo();
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState<string>('');
    const [distributorDevices, setDistributorDevices] = useState<IDevice[]|undefined>(distributorDetails?.devices);
    const getDevicesByAssignment = (assinedtoClinic: boolean) => {
        return distributorDevices && distributorDevices.filter(device => device.isAssignedToClinic === assinedtoClinic)
        .sort((a, b) => {
            if (a.serialNumber === null || a.serialNumber === undefined) return 1;
            if (b.serialNumber === null || b.serialNumber === undefined) return -1;
            return a.serialNumber.localeCompare(b.serialNumber);
    })}
    useEffect(()=>{
        setDistributorDevices(distributorDetails?.devices);
    }, [distributorDetails, setDistributorDevices])

    useEffect(() => {
        if(distributorDetails?.devices) {
            if(searchText !== '') {
                    var searchedDevices = distributorDetails?.devices.filter(device => 
                        (device.serialNumber?.toLowerCase().includes(searchText.toLowerCase()) ?? false) ||
                        (device.clinicName?.toLowerCase()?.includes(searchText.toLowerCase()) ?? false))
                    setDistributorDevices(searchedDevices)
                } else {
                    setDistributorDevices(distributorDetails?.devices)
                }
        }
    }, [searchText, setDistributorDevices, distributorDetails])
    return (
        <>
            <div>
                <div className="row m-2" style={{width:"97%"}}>
                    <SearchBar onChange={(text:string) => {setSearchText(text) }} />
                </div>
                {distributorDetails &&
                    <>
                        <div className='mt-4'>
                            <BoldText text={`${t('AssignedDevices')} (${getDevicesByAssignment(true)?.length ?? 0})`}></BoldText>
                            {getDevicesByAssignment(true)?.map((device, index) => (
                                <div key={index}>
                                    <AssignedDevice device={device} distributorId={distributorDetails.distributorId} updateDevices={getDistributorInfo}/>
                                </div>
                            ))}
                        </div>
                        <div className='mt-4'>
                            <BoldText text={`${t('DevicesOnStock')} (${getDevicesByAssignment(false)?.length ?? 0})`}></BoldText>
                            {getDevicesByAssignment(false)?.map((device, index) => (
                                <div key={index}>
                                    <UnassignedDevice serialNumber={device.serialNumber} deviceId={device.deviceId} clinics={distributorDetails.clinics} distributorId={distributorDetails.distributorId} updateDevices={getDistributorInfo}/>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </>
    )
}
export default Devices;