import Button from 'src/SharedComponents/Button/Button'
import Modal from 'src/Utils/CssModules/modal.module.css';
import './DeleteClinic.css'
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import warningIcon from 'src/images/delete-warning.svg';
import useOutsideClick from 'src/Utils/Hooks/UseOutSideClick/UseOutSideClick';
import CircularCheckbox from 'src/SharedComponents/CircularCheckbox/CircularCheckbox';
import { useDeleteClinicWrapper } from 'src/Utils/Hooks/useDeleteClinic/useDeleteClinicWrapper';

interface IDeleteClinicParams {
    clinicId: string;
    clinicName: string;
    updateClinics: () => void;
    onClose: () => void
}
const DeleteClinic: React.FC<IDeleteClinicParams> = ({ clinicId, clinicName, updateClinics, onClose }) => {
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [display, setdisplay] = useState('block');
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const {deleteClinic, requestResponse, requestError} = useDeleteClinicWrapper();
    useOutsideClick(wrapperRef, () => { close() })
    useEffect(() => {
        if(requestResponse){
            updateClinics();
            onClose();
        }
    }, [requestResponse, updateClinics, onClose])
    useEffect(()=>{
        if(requestError !== ''){
            onClose();
        }
    }, [requestError, onClose])
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    const submit = useCallback(() => {
        deleteClinic(clinicId, clinicName);
        setdisplay('none');
    }, [clinicId, clinicName, deleteClinic]);
    return (<>
        <div className='row'>
            <div id="myModal" className={`${Modal.modal}`} style={{ display: display, zIndex: 1003 }}>
                <div className={`${Modal.content} smallcontent`} ref={wrapperRef}>
                    <div className={Modal.header} style={{ textAlign: 'center' }}>
                        <img src={warningIcon} alt='warning' />
                    </div>

                    <div className={Modal.body}>
                        <div className='row delete-clinic'>
                            <div className='col' style={{maxWidth: 'fit-content'}}>
                                {t('Deleting')}
                            </div>
                            <div className='col' style={{maxWidth: 'fit-content', padding: '0px', whiteSpace: 'nowrap'}}>
                                "{clinicName}"
                            </div>
                        </div>
                        <div className='row mt-4' style={{ justifyContent: 'center', textAlign: 'center' }}>
                            <Trans i18nKey='DeleteClinicInstruction'/>
                        </div>
                        <div className='row my-3' style={{ justifyContent: 'center', textAlign: 'center' }}>
                            <CircularCheckbox label={t('DeleteClinicConfirmation')} onChecked={(isChecked) => setDisableSubmit(!isChecked)}/>
                        </div>
                    </div>

                    <div className={Modal.footer}>
                        <div className="row mb-3">
                            <div className='col-6'>
                                <Button
                                    backgroundColor='none'
                                    color='#000000'
                                    testId='close'
                                    onClick={() => { close() }}
                                    text={t('Cancel')}
                                    width='141px'
                                    height='48px'
                                    borderRadius='24px'
                                    borderColor="#000000" />
                            </div>
                            <div className='col-6 d-flex justify-content-end'>
                                <Button
                                    backgroundColor='#FF0F00'
                                    color='white'
                                    testId='ok'
                                    onClick={() => { submit() }}
                                    text={t('Delete')}
                                    width='141px'
                                    height='48px'
                                    disable={disableSubmit}
                                    borderRadius='24px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default DeleteClinic