import { Outlet, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import NavbarContentWrapper from "../LayoutWrapper/NavbarContentWrapper";
import NavbarInfoContainer from "../NavbarInfoContainer/NavbarInfoContainer";
import { useGetDistributorInfoWrapper } from "src/Utils/Hooks/useGetDistributorInfo/userGetDistributorInfoWrapper";
import { useEffect } from "react";

const MainContent : React.FC = () => {
    const userNotFountStatusCode = 404;
    const navigate = useNavigate();
    const { distributorDetails, getDistributorInfo, distributorError, statusCode, distributorLoading } = useGetDistributorInfoWrapper()

      useEffect(() => {
        if(distributorError && statusCode === userNotFountStatusCode) {     
            navigate('/noaccess');
        }
    }, [distributorError, statusCode, navigate])

    useEffect(() => {
        if(!distributorDetails) {
            getDistributorInfo()
        }
    }, [getDistributorInfo, distributorDetails ])
    return (
        <>
            {distributorDetails && !distributorLoading &&
                <>
                    <div>
                        <Header />
                        <NavbarInfoContainer />
                    </div>
                    <NavbarContentWrapper >
                        <Outlet />
                    </NavbarContentWrapper>
                </>
            }
        </>
    )
}
export default MainContent;

