import React from 'react';
import { useRef } from 'react';
import { TopItems } from './NavbarItems';
import SubNavBar, { INavbarItems } from 'src/SharedComponents/Navbar/SubNavBar/SubNavBar';

const NavBar: React.FC = () => {
  const refNavbarItems = useRef<INavbarItems>({items: TopItems});
  return (
      <SubNavBar itemsList={refNavbarItems.current.items}  />
  );
};
export default React.memo(NavBar);
