import React from 'react';
import './DropDown.css';
import downArrow from 'src/images/down-arrow.png';
import { memo } from "react";

interface IChildren {
    children: React.ReactNode;
    selectedItem:string;
}
const DropDown: React.FC<IChildren> = ({children, selectedItem}) => {
    
    return (
        <div  >
            <div className="row menu-icons" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-md-6 channel-icon">
                    <img src={selectedItem} alt='channel' className='icons-size'/>
                </div>
                <div className="col-md-6 channel-options">
                    <img src={downArrow} alt='channel' style={{width:'30px', height:'22px'}}/>
                </div>
            </div>
            <ul className={`dropdown-menu`} aria-labelledby="dropdownMenuButton1">
               {children} 
            </ul>
            
        </div>
    );
};

export default memo(DropDown);
