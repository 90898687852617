import React from 'react';

interface IChildren {
    firstName: string;
    lastName: string;
    onClick?: () => void;
}
const ProfileIcon: React.FC<IChildren> = ({ onClick, firstName, lastName }) => {
    return (
        <div style={{minWidth: '48px', minHeight: '48px', cursor: 'pointer'}} id='profile-icon'>
            {!(firstName || lastName) ?
                (
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <circle cx="24" cy="24" r="24" fill="white" />
                        <circle cx="24" cy="24" r="23" stroke="#A8927D" strokeOpacity="0.16" strokeWidth="2" strokeDasharray="4 4" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <circle cx="24" cy="24" r="24" fill="#A8927D" fillOpacity="0.12" />
                        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fill="var(--sandrift, #A8927D)" fontWeight={600} fontSize="20">{firstName ? firstName[0].toUpperCase() : ''}{lastName ? lastName[0].toUpperCase() : ''}</text>
                    </svg>)
            }

        </div>
    );
};

export default ProfileIcon;
