import React from 'react';
import './MessagingChannel.css'; // Import the updated CSS
import DropDown from 'src/SharedComponents/DropDown/DropDown';
import InputBox from 'src/SharedComponents/InputBox/InputBox';
import { memo } from "react";
import DropDownItem from 'src/SharedComponents/DropDown/DropDownItem';
import { Facebook, getChannelTypeIcon, Instagram, Other, WeChat, WhatsApp } from 'src/Utils/Hooks/useGetDistributorInfo/SupportChannalTypes';
export interface IMessagingChannal{
    supportChannel?: string,
    supportChannelType?: string
    onChannalChange: (supportChannel:string) => void
    onChannalTypeChange: (supportChannelType:string) => void
}

const MessagingChannel: React.FC<IMessagingChannal> = ({supportChannel, supportChannelType, onChannalChange, onChannalTypeChange}) => {
    const channalIcon = getChannelTypeIcon(supportChannelType)
    const whatsapp = getChannelTypeIcon(WhatsApp)
    const wechat = getChannelTypeIcon(WeChat)
    const facebook = getChannelTypeIcon(Facebook)
    const instagram = getChannelTypeIcon(Instagram)
    const other = getChannelTypeIcon(Other)
    return (
        <div className="messaging-channel-container">
                <DropDown selectedItem={channalIcon}>
                    <div style={{paddingLeft:'18px'}}>
                        <DropDownItem logoSrc={whatsapp} disabled= {supportChannelType === WhatsApp} text={WhatsApp} OnChange={(item) => onChannalTypeChange(item)}/>
                        <DropDownItem logoSrc={wechat} disabled= {supportChannelType === WeChat} text={WeChat} OnChange={(item) => onChannalTypeChange(item)} />
                        <DropDownItem logoSrc={facebook} disabled= {supportChannelType === Facebook} text={Facebook} OnChange={(item) => onChannalTypeChange(item)} />
                        <DropDownItem logoSrc={instagram} disabled= {supportChannelType === Instagram} text={Instagram} OnChange={(item) => onChannalTypeChange(item)} />
                        <DropDownItem logoSrc={other} disabled= {supportChannelType === Other} text={Other} OnChange={(item) => onChannalTypeChange(item)}/>
                    </div>
                </DropDown>
            <div className="description-text">
                <InputBox onChange={(channalText) =>{onChannalChange(channalText)}} backGround='none' value={supportChannel}/>
            </div>
            
        </div>
    );
};

export default memo(MessagingChannel);
