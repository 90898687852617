
const DeviceGrid:React.FC<{children:React.ReactNode}> = ({children}) => {

    return(
        <> 
          <div className="row mt-4" style={{ paddingLeft: '1vw' }}>
                <div className='row d-flex align-items-center device-row p-4'>
                   {children}
                </div>
            </div>
        </>
    )
}
export default DeviceGrid;