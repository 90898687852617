import './ButtonWithIcon.css'

export interface IButtonParams {
    label: string;
    icon?: string;
    color?: string;
    backgroundColor?: string;
    onClick: () => void;
    testId?: string;
    disable?:boolean;
    fontSize?: string;
    
};
const ButtonWithIcon: React.FC<IButtonParams> = ({ label, icon, backgroundColor, color, testId, fontSize, onClick, disable }) => {
    const defaultBackgroundColor = backgroundColor? backgroundColor : 'linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)';
    const defaultColor = color? color : 'white';
    return (
        <button 
              type='button'
              className='btn buttonWrapper' 
              data-testid={testId} 
              style={{ background: defaultBackgroundColor, color: defaultColor, borderColor: defaultColor, fontSize: fontSize }} 
              onClick={() => onClick()}
              disabled ={disable}
              >
            {icon &&
                <span className='iconWrapper'>
                    <i className={`${icon}`}></i>
                </span>
            }
            {label}
        </button>
    );
};

export default ButtonWithIcon;