import { useTranslation } from 'react-i18next';
import logo from 'src/images/logo.svg';
import './NoAccess.css'
import ButtonWithIcon from 'src/SharedComponents/ButtonWithIcon/ButtonWithIcon';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { Link } from 'react-router-dom';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

const NoAccess: React.FC = () => {
  const { t } = useTranslation();
  const clinicPortal = Config.GetConfigData().clinicPortalUrl;
  const { instance } = useMsal();
  useEffect(() => {
    const userOid = instance.getActiveAccount()?.idTokenClaims?.oid;
    logger.logWarning(`User with object id ${userOid} is trying to access partner portal, but user has no access to it.`);
  }, [instance])

  return (
    <>
      <div style={{ color: '#f5f2f0' }}>
        <div className="container">
          <div className="row header-container">
            <div className="pt-2 col-md-3 col-lg-3 col-xl-3">
              <div style={{ display: 'inline-block' }}>
                <span className="px-3"><img src={logo} alt='logo' /></span>
                <span className="portal-name">{t('PartnerPortal')}</span>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '50%', marginLeft: '22%', marginTop: '14%' }}>
            <div style={{ marginLeft: '26%' }}>
              <div className="row" >
                <h2 className='header'>{t('Welcome')}</h2>
              </div>
              <div className='row'>
                <div className='mt-3'>
                  <p style={{ color: 'black' }}>
                    <b> {t('AccessedWrongPortal')} </b>
                  </p>
                  <p style={{ color: 'black' }}>
                    {t('ContactClinicPortal')}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='mt-3'>
                  <Link to={clinicPortal} className='nounderline'>
                    <ButtonWithIcon
                      onClick={() => { }}
                      label={t('GoToClinicPortal')}
                      icon='bi bi-chevron-right'
                      testId='GoToClinicPortal'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default NoAccess;