import { useState } from "react";

import "./SearchBar.css";
interface ISearchBarParams {
  onChange: (text: string) => void;
  value?: string;
}
const SearchBar : React.FC<ISearchBarParams> = ({onChange,value}) => {
  let val = value ? value : "";
  const [input, setInput] = useState<string>(val);
  return (
    <div className="input-wrapper w-100">
      <input className="search w-100"
        placeholder=""
        value={input}
        onChange={(e) => 
          {
            var inputVal = e.target.value;
            setInput(inputVal)
            onChange(inputVal)
          }
        }

      />
    </div>
  );
};

export default SearchBar;