import tableStyles from 'src/Utils/CssModules/table.module.css';
import { SortConfig } from 'src/Utils/Hooks/SortableData/useSortableData ';
export interface ITableHead<T> {
    requestSort: (key: keyof T) => void
    sortConfig: SortConfig<T> | null;
    columnName: string;
    paddingLeft?:string
    columnkey: keyof T
}

const TableHead = <T extends object>({ requestSort, columnName, sortConfig, paddingLeft,  columnkey}: ITableHead<T>) => {
    const getSortIcon = (key: keyof T) => {
        if (!sortConfig || sortConfig.key !== key) {
          return null;
        }
        if (sortConfig.direction === 'ascending') {
            return 'bi bi-chevron-up';
        }
        return 'bi bi-chevron-down';;
      };

    return <>
           <th className={tableStyles.heading}
                style={{ paddingLeft: `${paddingLeft && paddingLeft}`, cursor: "pointer" }}
                onClick={() => requestSort(columnkey)}>
                {columnName} <i className={`${getSortIcon(columnkey)}`}></i>
            </th>
        </>;
  };

export default TableHead;