import React from "react";
import GridView from "src/SharedComponents/GridView/GridView";
import { IClinic, UserStatus } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import personLogo from 'src/images/person-icon.svg';
import './ClinicInfo.css'
import LogoText from "src/SharedComponents/LogoText/LogoText";
import ClinicManagement from "../ClinicManagement/ClinicManagement";
import { useTranslation } from "react-i18next";

interface IClinicInfoParams {
  clinic: IClinic;
  getClinicInfo: () => void;
  onClose: () => void;
}

const ClinicInfo: React.FC<IClinicInfoParams> = ({ clinic, getClinicInfo, onClose }) => {
  const { t } = useTranslation();
  const pendingText = clinic.distributorClinicAdminUserStatus === UserStatus.Invited ? `(${t('Pending')})` : '';
  return (
    <div>
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><span onClick={onClose} className="clickable-item">{t('Clinics')}</span></li>
          <li className="breadcrumb-item active" aria-current="page">{clinic.name}</li>
        </ol>
      </nav>
      {/* Used this css class in order to alter the css styles of gridview */}
      <div className="clinic-info" >
        <GridView title={clinic?.name} headerRightAlignedItem={<ClinicManagement clinic={clinic} getClinicInfo={getClinicInfo}/>}>
          <div className="d-flex flex-column">
            <LogoText logoSrc={personLogo} text={clinic.distributorClinicAdminUserEmail} additionalText={pendingText} />
            <LogoText logoSrc={mailLogo} text={clinic.email} />
            <LogoText logoSrc={phoneLogo} text={clinic.contactNumber} />
            <LogoText logoSrc={mapPinLogo} text={clinic.address} />
          </div>
        </GridView>
      </div>
    </div>
  );
};

export default ClinicInfo;
