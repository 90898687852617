import Dialog from "src/Components/Dialog/Dialog";
import './CreateClinic.css';
import PhoneInputBox from "src/SharedComponents/PhoneInputBox/PhoneInputBox";
import Button from "src/SharedComponents/Button/Button";
import { ICreateClinicRequest } from "src/Utils/Hooks/useCreateClinic/useCreateClinic";
import { useCallback, useEffect, useRef, useState } from "react";
import InputBoxWithLabel from "src/SharedComponents/InputBoxWithLabel/InputBoxWithLabel";
import EmailInput from "src/SharedComponents/EmailInput/EmailInput";
import TextBox from "src/SharedComponents/TextBox/TextBox";
import ErrorMessage from "src/SharedComponents/ErrorMessage/ErrorMessage";
import { useCreateClinicAndInviteAdminWrapper } from "src/Utils/Hooks/useCreateClinic/useCreateClinicAndInviteAdminWrapper";
import { isValidEmail, isValidPhoneNumber } from "src/Utils/ValidationUtils/ValidationUtils";
import { useTranslation } from "react-i18next";
import { logger } from "src/Utils/LoggerService/LoggerService";

interface ICreateClinicParams {
  onClose: () => void;
  updateClinics: () => void;
}
const CreateClinic: React.FC<ICreateClinicParams> = ({ updateClinics, onClose }) => {
  const [display, setdisplay] = useState('block');
  const { t } = useTranslation();
  let createClinicReq: ICreateClinicRequest = {
    name: '',
    email: '',
    address: '',
    contactNumber: '',
    clinicAdminemail: ''
  }


  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    contactNumber: '',
    address: '',
    clinicAdminEmail: ''
  });

  const createClinicRequest = useRef<ICreateClinicRequest>(createClinicReq);
  const [clinicAdminEmail, setClinicAdminEmail] = useState<string>('')
  const { createClinicAndInviteAdmin, inviteAdminResponse, inviteAdminError, createClinicError } = useCreateClinicAndInviteAdminWrapper();

  const isValidRequest = useCallback(() => {
    const errors: any = {};
    let isValid = true;
    setValidationErrors(errors);
    if (createClinicRequest.current.name === '') {
      errors.name = t('NameRequired');
      isValid = false;
    }

    if (createClinicRequest.current.email === '') {
      errors.email = t('EmailAddressRequired');
      isValid = false;
    }

    else if (!isValidEmail(createClinicRequest.current.email)) {
      errors.email = t('InvalidEmailAddress');
      isValid = false;
    }

    if (createClinicRequest.current.contactNumber === '') {
      errors.contactNumber = t('PhoneRequired');
      isValid = false;
    }
    else if (!isValidPhoneNumber(createClinicRequest.current.contactNumber)) {
      errors.contactNumber = t('InvalidPhoneNumber');
      isValid = false;
    }

    if (createClinicRequest.current.address === '') {
      errors.address = t('AddressRequired');
      isValid = false;
    }

    if (clinicAdminEmail === '') {
      errors.clinicAdminEmail = t('EmailAddressRequired');
      isValid = false;
    }

    else if (!isValidEmail(clinicAdminEmail)) {
      errors.clinicAdminEmail = t('InvalidEmailAddress');
      isValid = false;
    }

    setValidationErrors(errors);

    return isValid;
  }, [createClinicRequest, clinicAdminEmail, setValidationErrors, t])

  const createClinic = () => {
    if (isValidRequest()) {
      logger.logInformation(`The request has been submitted to create clinic with Clinic name: ${createClinicReq.name}, Clinic admin email: ${clinicAdminEmail}`);
      createClinicAndInviteAdmin({ createClinic: createClinicRequest.current, clinicAdminEmail: clinicAdminEmail });
      setdisplay('none');
    }
  }

  useEffect(() => {
    if (inviteAdminResponse || inviteAdminError !== '') {
      updateClinics();
      onClose();
    }
  }, [inviteAdminResponse, inviteAdminError, updateClinics, onClose])

  useEffect(() => {
    if (createClinicError) {
      onClose();
    }
  }, [createClinicError, onClose])

  return (
    <div style={{ display: display }}>
      <Dialog
        title={t('CreateNewClinic')}
        headerAlign='left'
        submitText=""
        onClose={() => onClose()}
        onSubmit={() => { }}
        paddingTop='180px'
        disableSubmit={true}
        hideSubmit={true}
      >
        <div style={{ paddingRight: '26px', width:'382px' }}>
          <div>
            <InputBoxWithLabel
              onChange={(e) => { createClinicRequest.current.name = e }}
              label="ClinicName" />
            <ErrorMessage message={validationErrors.name}></ErrorMessage>
          </div>
          <div className="mt-3">
            <div className="label">
              {t('ClinicEmailAddress')}
            </div>
            <div className="mt-2">
              <EmailInput
                onChange={(e) => createClinicRequest.current.email = e} />
            </div>
            <ErrorMessage message={validationErrors.email}></ErrorMessage>
          </div>
          <div className="mt-3">
            <div className="label mb-2">{t('ClinicPhoneNumber')}</div>
            <PhoneInputBox
              onChange={(e) => createClinicRequest.current.contactNumber = e} defaultCountryCode="de" />
            <ErrorMessage message={validationErrors.contactNumber}></ErrorMessage>
          </div>
          <div className="mt-3">
            <TextBox label='Address' onChange={(e: string) => createClinicRequest.current.address = e} />
            <ErrorMessage message={validationErrors.address}></ErrorMessage>
          </div>
          <div className="mt-3">
            <div className="label">
              {t('AdminEmailAddress')}
            </div>
            <div className="mt-2">
              <EmailInput
                onChange={(e) => setClinicAdminEmail(e)} />
            </div>
            <ErrorMessage message={validationErrors.clinicAdminEmail}></ErrorMessage>
          </div>
          <div className="mt-3" style={{position:'relative', left:'62%'}}>
            <Button
              testId='ok'
              onClick={() => { createClinic() }}
              text={t("CreateClinic")}
              width="124px"
            />
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default CreateClinic;