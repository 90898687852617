import { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreOptionsMenu from "src/Components/MoreOptions/MoreOptions";
import ReinviteAdmin from "../ReinviteAdmin/ReinviteAdmin";
import { IClinic } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
interface IClinicManagement {
    clinic: IClinic;
    getClinicInfo: () => void;
}
const ClinicManagement: React.FC<IClinicManagement> = ({ clinic, getClinicInfo }) => {
    const { t } = useTranslation()
    const [showReinviteDialog, setShowReinviteDialog] = useState<boolean>(false);
    return (
        <>
            {showReinviteDialog &&
                <ReinviteAdmin
                    email={clinic.distributorClinicAdminUserEmail}
                    clinicId={clinic.clinicId}
                    userStatus={clinic.distributorClinicAdminUserStatus}
                    onClose={() => { setShowReinviteDialog(false) }}
                    onSendInvitation={getClinicInfo}
                />
            }
            <div className="px-2">
                <MoreOptionsMenu>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><div className="dropdown-item" key="1" onClick={() => setShowReinviteDialog(true)}>{t('ResendInvitation')}</div></li>
                    </ul>
                </MoreOptionsMenu>
            </div>
        </>

    )
}
export default ClinicManagement