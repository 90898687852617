import { useEffect, useRef, useState } from "react";
import Dialog from "src/Components/Dialog/Dialog";
import Button from "src/SharedComponents/Button/Button";
import { useTranslation } from "react-i18next";
import { useReinviteClinicAdminWrapper } from "src/Utils/Hooks/useCreateClinic/useReinviteClinicAdminWrapper";
import { useInviteClinicAdminWrapper } from "src/Utils/Hooks/useCreateClinic/useInviteClinicAdminWrapper";
import { UserStatus } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import InputBox from "src/SharedComponents/InputBox/InputBox";
import { isValidEmail } from "src/Utils/ValidationUtils/ValidationUtils";
import ErrorMessage from "src/SharedComponents/ErrorMessage/ErrorMessage";
import { logger } from "src/Utils/LoggerService/LoggerService";

interface IReinviteAdminParams {
    email: string;
    clinicId: string;
    onClose: () => void;
    onSendInvitation: (mail: string) => void;
    userStatus: string;
}

const ReinviteAdmin: React.FC<IReinviteAdminParams> = ({ clinicId, email, userStatus, onSendInvitation, onClose }) => {
    const [display, setDisplay] = useState('block');
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const { reqestResponse, reqestError, reinviteClinicAdmin } = useReinviteClinicAdminWrapper();
    const { inviteRequestResponse, inviteRequestError, inviteAdmin } = useInviteClinicAdminWrapper();
    const [disableSetInvite, setDisableSetInvite] = useState<boolean>(userStatus === UserStatus.Created || email === '');
    const newEmail = useRef<string>(email)
    useEffect(() => {
        if (reqestResponse) {
            onSendInvitation(newEmail.current);
            onClose();
        }
    }, [reqestResponse, newEmail, onSendInvitation, onClose])
    useEffect(() => {
        if (reqestError !== '') {
            onClose();
        }
    }, [onClose, reqestError])

    const isValidRequest = () => {
        let isValid = true;
        if (newEmail.current === '') {
            setError(t('EmailAddressRequired'));
            isValid = false;
        }
        else if (!isValidEmail(newEmail.current)) {
            setError(t('InvalidEmailAddress'));
            isValid = false;
        }
        return isValid;
    }

    useEffect(() => {
        if (inviteRequestResponse) {
            onSendInvitation(newEmail.current);
            onClose();
        }
    }, [inviteRequestResponse, newEmail, onSendInvitation, onClose])
    useEffect(() => {
        if (inviteRequestError !== '') {
            onClose();
        }
    }, [onClose, inviteRequestError])

    const handleSendInvitation = () => {
        if (isValidRequest()) {
            if (newEmail.current === email) {
                logger.logInformation(`A request has been submitted to re-invite the clinic admin. EmailId is ${email}, clinicId is ${clinicId}.`)
                reinviteClinicAdmin(email, clinicId)
            } else {
                logger.logInformation(`A request has been submitted to invite the clinic admin. EmailId is ${newEmail.current}, clinicId is ${clinicId}.`)
                inviteAdmin(newEmail.current, clinicId);
            }
            setDisplay('none');
        }

    }
    return (
        <div style={{ display: display }}>
            <Dialog
                title='InviteClinicAdmin'
                headerAlign='left'
                submitText={t("SendInvitation")}
                contentWidth="35%"
                onClose={() => { onClose() }}
                onSubmit={() => { }}
                hideSubmit={true}
            >
                <>
                    <div className="label" style={{textAlign:'left'}}>
                        {t('EmailAddress')}
                    </div>
                    <div className="row mt-2">
                        <div className="col-8" style={{ paddingRight: '0' }}>
                            <InputBox
                                width='100%'
                                height='100%'
                                value={newEmail.current}
                                onChange={(e) => {
                                    newEmail.current = e;
                                    setDisableSetInvite((e === email && userStatus === UserStatus.Created));
                                }} />
                        </div>
                        <div className="col-4">
                            <Button
                                testId='ok'
                                onClick={handleSendInvitation}
                                text={t("SendInvitation")}
                                width="182px"
                                disable={disableSetInvite}
                            />
                        </div>
                        {error && <ErrorMessage message={error}></ErrorMessage>}
                    </div>
                </>
            </Dialog>
        </div>
    )
}

export default ReinviteAdmin;