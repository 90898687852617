import './DistributorName.css'
import { usePartnerInfo } from "src/Utils/Hooks/usePartnerInfoContext";
const DistributorName = () => {
    const { distributorDetails } = usePartnerInfo();
    return (
        <>
            {
                distributorDetails &&
                <div className="title-font">{distributorDetails?.name}</div>
            }
        </>
    )
}
export default DistributorName;