import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Login from "../Authentication/Login";
import "./Header.css"
import { useState } from "react";
import Profile from "../Profile/Profile";
import ProfileIcon from "src/SharedComponents/ProfileIcon/ProfileIcon";
import DistributorName from "../DistributorName/DistributorName";
import { useTranslation } from "react-i18next";
import logo from 'src/images/logo.svg';

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const lastName = isAuthenticated ? instance.getActiveAccount()?.idTokenClaims?.family_name : '';
  const firstName = isAuthenticated ? instance.getActiveAccount()?.idTokenClaims?.given_name : '';
  const [showDiv, setShowDiv] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {showDiv &&
        <Profile firstName={`${firstName}`} lastName={`${lastName}`} email="" onClose={() => setShowDiv(false)} />
      }
      <div className="container">
        <div className="row header-container">
          <div className="pt-2 col-md-3 col-lg-3 col-xl-3">
            <div style={{display: 'inline-block'}}> 
              <span className="px-3"><img src={logo} alt='logo'/></span>
              <span className="portal-name">{t('PartnerPortal')}</span>
          </div>
          </div>
          {isAuthenticated &&
            <div className="col-md-4 col-lg-5 col-xl-6">
              <DistributorName />
            </div>
          }
          <div className="col-md-3 col-lg-1 col-xl-2 d-flex justify-content-end" style={{ right: '24px', position: 'absolute' }}>
            {isAuthenticated ? (
              <div className="col-md-3 col-lg-1 col-xl-2 d-flex justify-content-end" onClick={() => setShowDiv(true)}>
                <ProfileIcon firstName={`${firstName}`} lastName={`${lastName}`} />
              </div>
            ) : (
              <Login />
            )}
          </div>

        </div>
      </div>
    </>)
}
export default Header;