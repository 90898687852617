import React, { useCallback, useState } from 'react';
import Modal from 'src/Utils/CssModules/modal.module.css';
import closeIcon from 'src/images/close.png';
import './Dialog.css';
import Button from 'src/SharedComponents/Button/Button';
import { useTranslation } from 'react-i18next';

export interface IConfirmDialogParams {
    title: string
    children: React.ReactNode,
    onSubmit: () => void,
    onClose: () => void,
    submitText: string,
    paddingTop?: string,
    disableSubmit?: boolean,
    headerAlign?: any,
    contentWidth?: string,
    hideSubmit?: boolean,
}
const ConfirmDialog: React.FC<IConfirmDialogParams> = ({ title, children, submitText, paddingTop, contentWidth, onSubmit, onClose, headerAlign, disableSubmit, hideSubmit }) => {
    const [display, setdisplay] = useState('block');
    const { t } = useTranslation();
    
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    const submit = useCallback(() => {
        setdisplay('none');
        onSubmit();
    }, [onSubmit]);
    return (
        <div className='row'>
            <div id="myModal" className={`${Modal.modal}`} style={{ display: display, zIndex: 1003, paddingTop: paddingTop }}>
                <div className={Modal.content} style={{width: contentWidth}} id="modalContent">
                    <div className={`${Modal.header} row`} style={{ textAlign: headerAlign, display: 'flex' }}>
                        <p className='col-7'>{t(title)}</p>
                        <span className='col-5 d-flex justify-content-end' onClick={() => { close() }}>
                            <img src={closeIcon} className='close-icon' alt="close"/>
                        </span>
                    </div>

                    <div className={Modal.body}>
                        {children}
                    </div>
                    <div className={Modal.footer} >
                        <div className="row" >
                            <div className='col-auto me-auto' hidden={hideSubmit}>
                                <Button
                                    testId='ok'
                                    onClick={() => { submit() }}
                                    text={submitText}
                                    width='100px'
                                    disable={disableSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConfirmDialog;