import './GridView.css'

export interface IGridViewParams {
    title: string
    children: React.ReactNode,
    headerRightAlignedItem?: React.ReactNode
}
const GridView: React.FC<IGridViewParams> = ({ children, title, headerRightAlignedItem }) => {
    return (
        <>
            <div className='row d-flex mb-4 gridview'>
                <div className='m-2'>
                    <div className="row m-3">
                        <div className='col gridview-header'>
                            <b>{title}</b>
                        </div>
                        {
                            headerRightAlignedItem &&
                            <div className='col-2 d-flex justify-content-end extra-text-right' style={{ height: '22px' }}>
                                {headerRightAlignedItem}
                            </div>
                        }
                    </div>

                    <hr style={{ color: '#A8927D' }} />
                    <div className="m-3">
                        {children}
                    </div>
                </div>

            </div>
        </>
    )
}
export default GridView; 