import { useTranslation } from "react-i18next";
import Button from "src/SharedComponents/Button/Button";
import PopupDialog from "src/SharedComponents/PopupDialog/PopupDialog";
import tableStyles from 'src/Utils/CssModules/table.module.css';
import { IClinic } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import close from 'src/images/close.png';
import './AssignDeviceToClinic.css';
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter";
import SearchBar from "src/SharedComponents/SearchBar/SearchBar";
import { useRef, useState } from "react";
import useSortableData, { SortConfig } from "src/Utils/Hooks/SortableData/useSortableData ";
import TableHead from "src/SharedComponents/Table/TableHead";
import { useSearch } from "src/Utils/Hooks/SearchData/useSearch";
export interface IAssignDeviceToClinic {
    onClose: () => void,
    clinics: IClinic[],
    serialNumber: string;
    onSubmit: (clinicID: string) => void;
}
const AssignDeviceToClinic: React.FC<IAssignDeviceToClinic> = ({ onClose, onSubmit, clinics, serialNumber }) => {
    const { t } = useTranslation();

    let conf: SortConfig<IClinic> = {
        key: 'name',
        direction: 'ascending'
    };
    
    const [searchText, setSearchText] = useState<string>('');
    const searchKeys = useRef<(keyof IClinic)[]>(['name'])
    const filteredClinics = useSearch(clinics, searchText, searchKeys.current);
    const { items, requestSort, sortConfig } = useSortableData(filteredClinics, conf);

    return (
        <>
        <div className="assignToClinic">
            <PopupDialog onClose={() => onClose()} marginTop="120px">
                <div className="assign-device-header d-flex flex-column row">
                    <div className="row">
                        <div className="col-md-10">
                            <span style={{ fontWeight: '600', fontSize: '24px' }}>{t('AssignDevice', { 'serialNumber': serialNumber })}</span>
                        </div>
                        <div className="col-md-2">
                            <i style={{ color: '#A8927D', cursor: 'pointer' }} onClick={() => { onClose() }}>
                                <img src={close} className='close-icon' alt="close" />
                            </i>
                        </div>
                    </div>
                    <div className="row ps-4" style={{ fontSize: '16px', color: '#A8927D', fontWeight: '400' }}>
                        {t('SelectAClinic')}
                    </div>
                    <div className="row mt-2">
                        <SearchBar onChange={(text:string) => {setSearchText(text) }} />
                    </div>
                </div>
                <div className="table-responsive" style={{overflowX:"hidden", borderRadius:'20px', marginBottom:'2px'}}>
                 <table data-testid="table" className={`table ${tableStyles.tableStriped}`}>
                        <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                                <TableHead<IClinic>
                                    columnName={t('ClinicName')} 
                                    paddingLeft="25px"
                                    requestSort={requestSort}
                                    sortConfig={sortConfig}
                                    columnkey="name"
                                />
                                <TableHead<IClinic>
                                    columnName={t('SetupDate')} 
                                    requestSort={requestSort}
                                    sortConfig={sortConfig}
                                    columnkey="setUpDate"
                                />
                                 <th className={tableStyles.heading}>{ }</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((clinic, i) => (
                                <tr key={i}>
                                    <td className={tableStyles.data} style={{width:"39%", paddingLeft:'25px' }}>
                                        {clinic.name}
                                    </td>
                                    <td className={tableStyles.data} style={{width:"39%"}}>
                                        {formatDateString(clinic.setUpDate)}
                                    </td>
                                    <td className={tableStyles.data} style={{ fontWeight: 600}}>
                                        <Button
                                            backgroundColor="#A8927D"
                                            color="white"
                                            text={t("Assign")}
                                            borderColor="none"
                                            fontSize="12px"
                                            width='127px'
                                            onClick={() => { onSubmit(clinic.clinicId) }}
                                        />
                                    </td>

                                </tr>
                            ))}
                           
                        </tbody>
                    </table>
                </div>
            </PopupDialog>
        </div>
        </>
    )
}
export default AssignDeviceToClinic;