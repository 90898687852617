import { useEffect } from "react";
import { useGetDistributorInfo } from "./useGetDistributorInfo"
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useGetDistributorInfoWrapper = () => {
    const { distributorDetails, distributorError, distributorLoading, getDistributorInfo, statusCode } = useGetDistributorInfo();
    const { setInfobar, setLoader, reset, setDistributorInfo } = usePartnerInfo();

    useEffect(() => {
        if(distributorError !== '') {
           logger.logError(`Error occurred while fetching distributor details: ${distributorError}`)
           setInfobar({message:distributorError,messageType: InfoMessageType.error })
        }
    }, [distributorError, setInfobar])
    useEffect(() => {
       setLoader(distributorLoading)
    },[distributorLoading, setLoader])

    useEffect(() => {
        reset();
    },[reset])

    useEffect(() => {
        if(distributorDetails) {
             setDistributorInfo(distributorDetails)
        }
    },[distributorDetails, setDistributorInfo])
    return {distributorDetails, distributorError, getDistributorInfo, statusCode, distributorLoading}
} 