import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { useInviteClinicAdmin } from "./useInviteClinicAdmin";

export const useInviteClinicAdminWrapper = () => {
    const { addClinicAdmin, reqestResponse: inviteRequestResponse, reqestLoading: inviteRequestLoading, reqestError: inviteRequestError } = useInviteClinicAdmin();
    const { setInfobar, setLoader } = usePartnerInfo();
    const adminDetails = useRef<{
        email: string;
        clinicId: string;
    }>({ email: '', clinicId: '' });
    useEffect(() => {
        if (inviteRequestError !== '') {
            logger.logError(`Error occur while inviting admin to clinic ${inviteRequestError}. EmailId is ${adminDetails.current.email}, clinicId is ${adminDetails.current.clinicId}.`);
            setInfobar({ message: inviteRequestError, messageType: InfoMessageType.error })
        }
    }, [inviteRequestError, setInfobar])
    useEffect(() => {
        if (inviteRequestResponse) {
            logger.logInformation(`Admin is invited successfully. EmailId is ${adminDetails.current.email}, clinicId is ${adminDetails.current.clinicId}.`);
            setInfobar({ message: 'Admin invitation link was sent successfully', messageType: InfoMessageType.success })
        }
    }, [inviteRequestResponse, setInfobar])
    useEffect(() => {
        setLoader(inviteRequestLoading)
    }, [inviteRequestLoading, setLoader])

    const inviteAdmin = useCallback((email: string, clinicId: string) => {
        logger.logInformation(`Request to invite admin with email ${email} and clinic id ${clinicId} was made`)
        adminDetails.current.email = email;
        adminDetails.current.clinicId = clinicId;
        addClinicAdmin(email, clinicId);
    },[addClinicAdmin]) 

    return {inviteRequestResponse, inviteRequestError, inviteAdmin}
}