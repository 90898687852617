import { INavbarItem } from "src/SharedComponents/Navbar/SubNavBar/SubNavBar";


export const TopItems: INavbarItem[] =  [
    
    {
        id:'contact',
        name:'Contact',
        hasChildren: false,
        routePath:'/contact',
        testid:'contact'
    },
    {
        id:'devices',
        name:'Devices',
        hasChildren: false,
        routePath:'/devices',
        testid:'devices'
    },
    {
        id:'clinics',
        name:'Clinics',
        hasChildren: false,
        routePath:'/clinics',
        testid:'clinics'
    },
    {
        id:'users',
        name:'Users',
        hasChildren: false,
        routePath:'/users',
        testid:'users'
    },
    {
        id:'downloads',
        name:'Downloads',
        hasChildren: false,
        routePath:'/downloads',
        testid:'downloads'
    }
]
  