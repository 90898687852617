import { usePartnerInfo } from 'src/Utils/Hooks/usePartnerInfoContext';
import './NavbarContentWrapper.css'
import Loader from 'src/SharedComponents/Loader/Loader';
const NavbarContentWrapper:React.FC<{children:React.ReactNode}> = ({children}) => {
    const { loader } = usePartnerInfo();
    return  (
        <>
       
        <div className="content-wrapper">
            <div className="row content-layout mt-5">
                   {loader && <Loader />}
                   {children}
            </div>
        </div>
        </>
    )
}
export default NavbarContentWrapper;