import './Downloads.css';
import MarketingMaterialDocuments from './MarketingMaterialDocuments/MarketingMaterialDocuments';
import TechnicalDocuments from "./TechnicalDocuments/TechnicalDocuments";
import TutorialVideos from "./TutorialVideos/TutorialVideos";

const Downloads = () => {
    return (
        <div>
            <TechnicalDocuments />
            <TutorialVideos />
            <MarketingMaterialDocuments />
        </div>
    )
}
export default Downloads;