import InfoBar, { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import NavBar from "../Navbar/NavBar";
import { usePartnerInfo } from "src/Utils/Hooks/usePartnerInfoContext";

const NavbarInfoContainer:React.FC = () => {
    const { show, setShow, infoMessageDetails } = usePartnerInfo();
    return(
        <>
        <div className="row mb-2">
          <NavBar/>
        </div>
        {show && infoMessageDetails.messageType !== InfoMessageType.none &&
            <div className="row">
               <InfoBar Message={infoMessageDetails.message} MessageType={infoMessageDetails.messageType} closeClick={() => {setShow(false)}} />
            </div>
        }
        </>
    )
}
export default NavbarInfoContainer;