import { useEffect, useState } from "react";
import { useCreateClinic } from "./useCreateClinic";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { logger } from "src/Utils/LoggerService/LoggerService";
export interface IDevice {
    deviceId:string,
    state:string,
    name:string
    serialNumber:string
 }
 export interface IClinicInfo {
     clinicId:string,
     name:string,
     devices:IDevice[],
     locations:string[],
     email:string,
     contactNumber:string,
     address:string
 }
export const useGetCreateClinicWrapper = () => {
    const { createNewClinic, reqestResponse, reqestError, reqestLoading } = useCreateClinic();
    const [clinicInfo, setClinicInfo] = useState<IClinicInfo>();
    const [createClinicError, setCreateClinicError] = useState<string>('')
    const { setInfobar, setLoader } = usePartnerInfo();
    useEffect(() => {
        if(reqestError !== '') {
            setCreateClinicError(reqestError)
            logger.logError(reqestError);
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
        if(reqestResponse) {
           logger.logInformation(reqestResponse.data);
           setClinicInfo(reqestResponse.data);
        }
    }, [reqestResponse, setInfobar])
    useEffect(() => {
       setLoader(reqestLoading)
    },[reqestLoading, setLoader])

    return {clinicInfo, createClinicError, createNewClinic}
} 