import { useState } from 'react';
import './EmailInput.css'

interface IEmailInput {
    width?: string;
    value?: string;
    onChange: (text: string) => void;
}
const EmailInput: React.FC<IEmailInput> = ({ width, value, onChange }) => {
    let initialVal = value ? value : '';
    const [email, setEmail] = useState<string>(initialVal);
    return (
        <div style={{width: width}}>
            <input
                type="text"
                value={email}
                className="form-control input-box"
                data-testid="e-mail"
                onChange={(e) => {
                    setEmail(e.target.value)
                    onChange(e.target.value)
                }}
            />
        </div>
    )
}
export default EmailInput