import { useEffect, useRef, useState } from "react";
import Dialog from "src/Components/Dialog/Dialog";
import Button from "src/SharedComponents/Button/Button";
import { IInviteUserRequest } from "src/Utils/Hooks/UserService/useGetUserService";
import { useGetUserServiceWrapper } from 'src/Utils/Hooks/UserService/useGetUserServiceWrapper';
import EmailInput from "src/SharedComponents/EmailInput/EmailInput";
import { isValidEmail } from "src/Utils/ValidationUtils/ValidationUtils";
import ErrorMessage from "src/SharedComponents/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";

interface IInviteUserParams {
  distributorId: string;
  updateUsers: () => void
  onClose: () => void;
}
const InviteUser: React.FC<IInviteUserParams> = ({ distributorId, updateUsers, onClose }) => {
  const { reqestResponse, reqestError, inviteUser } = useGetUserServiceWrapper();
  const [display, setdisplay] = useState('block');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  let req: IInviteUserRequest = {
    emailId: '', distributorId: distributorId
  }
  const inviteUserReq = useRef<IInviteUserRequest>(req);

  useEffect(() => {
    if (reqestResponse) {
      updateUsers();  
      onClose();
    }
  }, [reqestResponse, updateUsers, onClose])

  useEffect(() => {
    if (reqestError) {
      onClose();
    }
  }, [reqestError, updateUsers, onClose])

  const isValidRequest = () => {
    let isValid = true;
    if(inviteUserReq.current.emailId === ''){
      setError(t('EmailAddressRequired'));
      isValid = false;
    }
    else if(!isValidEmail(inviteUserReq.current.emailId))
    {
      setError(t('InvalidEmailAddress'));
      isValid = false;
    }
    return isValid;
  }


  const addUser = () => {
    if(isValidRequest()){
      inviteUser(inviteUserReq.current)
      setdisplay('none');
    }
  }

  return (
    <div style={{ display: display }}>
      <Dialog
        title='InviteNewUser'
        headerAlign='left'
        submitText={t("SendInvitation")}
        contentWidth="35%"
        onClose={() => onClose()}
        onSubmit={() => { }}
        hideSubmit={true}
      >
        <>
          <div className="label">
              {t('EmailAddress')}
          </div>
          <div className="row mt-2">
            <div className="col-8">
              <EmailInput
                onChange={(e) => {
                  inviteUserReq.current.emailId = e;
                }} />
              {error && <ErrorMessage message={error}></ErrorMessage>}
            </div>
            <div className="col-4">
              <Button
                testId='ok'
                onClick={() => { addUser() }}
                text={t("SendInvitation")}
                width="182px"
              />
          </div>
          </div>
          
        </>
      </Dialog>
    </div>
  )
}
export default InviteUser;