import deviceIcon from 'src/images/device.png';
import Button from "src/SharedComponents/Button/Button";
import DeviceGrid from '../DeviceGrid/DeviceGrid';
import BoldText from 'src/SharedComponents/BoldText/BoldTest';
import { useEffect, useState } from 'react';
import AssignDeviceToClinic from '../AssignDeviceToClinic/AssignDeviceToClinic';
import { IClinic } from 'src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo';
import { useGetDeviceServiceWrapper } from 'src/Utils/Hooks/DeviceService/useGetDeviceServiceWrapper';
import { useTranslation } from 'react-i18next';

export interface IUnAssgnedDevice {
    distributorId:string, 
    serialNumber:string,
    deviceId:string,
    clinics:IClinic[],
    updateDevices:() => void
}

const UnassignedDevice:React.FC<IUnAssgnedDevice> = ({distributorId,serialNumber, deviceId, clinics, updateDevices}) => {
    const [showAssignClinic, setShowAssignClinic] = useState(false);
    const { reqestResponse, assignDeviceToClinic} = useGetDeviceServiceWrapper();
    const { t } = useTranslation();
   
    useEffect(() => {
     if(reqestResponse) {
        updateDevices()
     }
    },[reqestResponse, updateDevices])


    return (
        <>
        {showAssignClinic && 
           <AssignDeviceToClinic onClose={() => setShowAssignClinic(false)} onSubmit={(cliniId) => {
            assignDeviceToClinic(deviceId, cliniId, distributorId,serialNumber)
            setShowAssignClinic(false);}} clinics={clinics}
            serialNumber={serialNumber}/>
        }
        <DeviceGrid>
            <div className='col-1 d-flex justify-content-center'>
                <img src={deviceIcon} alt="device" style={{ width: '40px', height: '40px' }} />
            </div>
            <div className='col-3' style={{ marginLeft: '19px' }}>
                <BoldText text={serialNumber}/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('SerialNumber')}</div>
            </div>

            <div className='col-3' style={{ marginLeft: '19px' }}>
                <BoldText text={t('Unassigned')}/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('Owner')}</div>
            </div>

            <div className='col-2' style={{ marginLeft: '19px' }}>
                <BoldText text=''/>
                <div className='text-left' style={{ fontSize: '12px' }}>{t('DateOfAssignment')}</div>
            </div>
            <div className='col-2' style={{ marginLeft: '19px' }}>
                <Button
                    backgroundColor="#A8927D29"
                    color="#A8927D"
                    text={t("AssignToClinic")}
                    borderColor="none"
                    fontSize="12px"
                    width='160px'
                    fontWeight='600'
                    onClick={() => {setShowAssignClinic(true) }}
                />
            </div>
            </DeviceGrid>
        </>
    )
}
export default UnassignedDevice;