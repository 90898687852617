import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { useDeleteClinic } from "./useDeleteClinic";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";

export const useDeleteClinicWrapper = () => {
    const {removeClinic, requestResponse, requestError, requestLoading} = useDeleteClinic();
    const { setInfobar, setLoader, reset } = usePartnerInfo();
    const clinicDetails = useRef<{
        clinicName: string;
        clinicId: string;
    }>({ clinicName: '', clinicId: '' });
    useEffect(() => {
        if (requestError !== '') {
            logger.logError(`Error occur while deleting the clinic ${requestError}.  ClinicId is ${clinicDetails.current.clinicId}.`);
            setInfobar({ message: requestError, messageType: InfoMessageType.error })
        }
    }, [requestError, setInfobar])
    useEffect(() => {
        if (requestResponse) {
            logger.logInformation(`Clinic is deleted successfully. ClinicId is ${clinicDetails.current.clinicId}.`);
            setInfobar({ message: `Clinic ${clinicDetails.current.clinicName} is deleted sucessfully`, messageType: InfoMessageType.success })
        }
    }, [requestResponse, setInfobar])
    useEffect(() => {
        setLoader(requestLoading)
    }, [requestLoading, setLoader])

    const  deleteClinic= useCallback((clinicId: string, clinicName: string) => {
        reset();
        logger.logInformation(`Request to delete the clinic ${clinicName} and clinic id ${clinicId} was made`)
        clinicDetails.current.clinicName = clinicName;
        clinicDetails.current.clinicId = clinicId;
        removeClinic(clinicId);
    },[removeClinic, reset]) 

    return {requestResponse, requestError, deleteClinic}
}