import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../ApiWrappers/usePostRequest";
import { logger } from "src/Utils/LoggerService/LoggerService";


export interface IInviteAdminRequest {
    email:string,
    isAdmin:boolean
}

export const useInviteClinicAdmin = () => {
    const { postRequest, reqestError, reqestLoading, reqestResponse } = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const addClinicAdmin = useCallback((email:string, clinicId:string) => {
        let clinicAdminInvit:IInviteAdminRequest = {
            email: email,
            isAdmin: true
        }
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}/user`;
        logger.logInformation(`Request to invite admin with email ${email} and clinic id ${clinicId} was made`)
        postRequest<IInviteAdminRequest>(clinicAdminInvit,requestUrl)
    },[baseUrl, postRequest]) 

    return { addClinicAdmin, reqestResponse, reqestLoading, reqestError};
};
