import { useCallback, useEffect, useRef } from "react";
import { ICreateClinicRequest } from "./useCreateClinic";
import { useGetCreateClinicWrapper } from "./useCreateClinicWrapper";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { useInviteClinicAdmin } from "./useInviteClinicAdmin";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";

export interface ICreateClinicAndInviteAdminReq {
    createClinic: ICreateClinicRequest,
    clinicAdminEmail: string
}

export const useCreateClinicAndInviteAdminWrapper = () =>{
    const {clinicInfo, createClinicError, createNewClinic}  = useGetCreateClinicWrapper();
    const { setInfobar, setLoader, reset } = usePartnerInfo();

    const {addClinicAdmin, reqestResponse: inviteAdminResponse, reqestLoading: inviteAdminLoading, reqestError: inviteAdminError } = useInviteClinicAdmin();
    const { t }  = useTranslation();
    const adminEmail = useRef('');
    const createClinicAndInviteAdmin = useCallback((req: ICreateClinicAndInviteAdminReq) => {
        reset();
        adminEmail.current = req.clinicAdminEmail;
        createNewClinic(req.createClinic)
    },[createNewClinic, reset])

    useEffect(() => {
        if(inviteAdminError !== '' && clinicInfo) {
           let errorMessage = t('CreateClinicErrorMsg', {clinicName: clinicInfo.name, error: inviteAdminError});
           logger.logError(errorMessage + `Clinic admin email is ${adminEmail.current}`)
           setInfobar({message: errorMessage, messageType: InfoMessageType.error })
        }
    }, [inviteAdminError, clinicInfo,  setInfobar, t])

    useEffect(() => {
        if(inviteAdminResponse && clinicInfo) {
           let successMessage = t('CreateClinicSuccessMsg', {clinicName: clinicInfo.name});
           logger.logInformation( successMessage + `Clinic admin email is ${adminEmail.current}`)
           setInfobar({message: successMessage, messageType: InfoMessageType.success });
        }
    }, [inviteAdminResponse, setInfobar, clinicInfo, t])


    useEffect(() => {
        if(clinicInfo?.clinicId) {
            addClinicAdmin(adminEmail.current, clinicInfo.clinicId);
        }
    },[clinicInfo?.clinicId, addClinicAdmin])

    useEffect(() => {
       setLoader(inviteAdminLoading)
    },[inviteAdminLoading, setLoader])
    
   
    return {createClinicAndInviteAdmin, inviteAdminResponse, inviteAdminError, createClinicError}
}

