import tableStyles from 'src/Utils/CssModules/table.module.css';
import { useGetDistributorInfoWrapper } from "src/Utils/Hooks/useGetDistributorInfo/userGetDistributorInfoWrapper";
import ProfileIcon from "src/SharedComponents/ProfileIcon/ProfileIcon";
import MoreOptionsMenu from "../MoreOptions/MoreOptions";
import { IUser } from "src/Utils/Hooks/useGetDistributorInfo/useGetDistributorInfo";
import { useEffect, useState } from "react";
import ButtonWithIcon from "src/SharedComponents/ButtonWithIcon/ButtonWithIcon";
import InviteUser from "./InviteUser/InviteUser";
import ResendInvitation from "./ResendInvite/ResendInvite";
import { useTranslation } from 'react-i18next';
import { usePartnerInfo } from 'src/Utils/Hooks/usePartnerInfoContext';

const Users:React.FC = () => {
    const  { getDistributorInfo } = useGetDistributorInfoWrapper();
    const { distributorDetails } = usePartnerInfo();
    const [sortedUsers, setSortedUsers] = useState(distributorDetails?.users || []);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const { t } = useTranslation();
    const sortUsers = (list: IUser[]) => {
      list.sort((a, b) => {
        // Check for null or undefined firstName and move them to the top
        if (a.firstName === null || a.firstName === undefined) return -1;
        if (b.firstName === null || b.firstName === undefined) return 1;
        return a.firstName.localeCompare(b.firstName);
      })
      return list;
    }

    useEffect(() => {
      if (distributorDetails?.users) {
        setSortedUsers(sortUsers(distributorDetails.users));
      }
    }, [distributorDetails]);
    
    return(
        <>
        {showConfirmDialog && distributorDetails &&
          <InviteUser
            distributorId={distributorDetails?.distributorId}
            updateUsers={getDistributorInfo}
            onClose={() => {setShowConfirmDialog(false)}}
          />
        }
        <div className="row d-flex justify-content-end">
          <ButtonWithIcon
            onClick={() => { setShowConfirmDialog(true) }}
            label={t('NewUser')}
            icon='bi bi-plus-lg'
            testId='newUser'
          />
      </div>
        <div className='row mt-5'>
        <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable}`}>
          {distributorDetails?.users &&
            <table style={{ width: '100%' }} data-testid="table" className={`table ${tableStyles.tableStriped}`}>
              <thead className={`${tableStyles.tableHead}`}>
                <tr key={'header'}>
                  <th></th>
                  <th className={tableStyles.heading}>{t('FirstName')}</th>
                  <th className={tableStyles.heading}>{t('LastName')}</th>
                  <th className={tableStyles.heading}>{t('Email')}</th>
                  <th className={tableStyles.heading}></th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user) => (
                  <tr key={user.email}>
                    <td>
                      <ProfileIcon
                        firstName={user.firstName}
                        lastName={user.lastName}
                      />
                    </td>
                    <td className={tableStyles.data} style={{fontWeight: 600}}>
                      {user.firstName? user.firstName : ''}
                    </td>
                    <td className={tableStyles.data} style={{fontWeight: 600}}>
                      {user.lastName? user.lastName : ''}
                    </td>
                    <td className={tableStyles.data}>{user.email}</td>
                    
                    <td className={tableStyles.data} style={{ display: 'flex', justifyContent: 'end' }}>                       
                      <MoreOptionsMenu disabled={!(user.firstName === null && user.lastName === null)}>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><ResendInvitation email={user.email} distributorId={distributorDetails?.distributorId}/></li>
                        </ul>
                      </MoreOptionsMenu>                                          
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
      </>
    )
}
export default Users;