

import {useCallback, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import axios from 'axios';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export enum  UserStatus {
  NotYetInvited='NotYetInvited',
  Invited='Invited',
  Created='Created'
}
export interface IUser {
   userId:string,
   email:string,
   firstName:string,
   lastName:string,
}
export interface IClinic {
   clinicId:string,
   name:string,
   email:string,
   contactNumber:string,
   distributorClinicAdminUserEmail: string,
   distributorClinicAdminUserStatus: string,
   address:string,
   setUpDate: string,
   devicesCount:string
}
export interface IDevice {
  name:string,
  serialNumber:string,
  assignedDate:string,
  isAssignedToClinic:boolean,
  deviceId:string, 
  clinicId?:string,
  clinicName?:string,
}
export interface IDistributorInfo {
    distributorId:string,
    name:string,
    clinics:IClinic[],
    users:IUser[],
    devices:IDevice[]
    email?:string,
    contact?:string,
    streetAddress?:string,
    city?:string,
    country?:string,
    zipCode?:string,
    address:string,
    supportContactDetail: ISupportContactDetail
}
export interface ISupportContactDetail {
  email?: string,
  contactNumber?: string,
  supportChannel?: string,
  supportChannelType?: string
}

export const useGetDistributorInfo = () => {
    const [distributorDetails, setDistributorDetails] = useState<IDistributorInfo>();
    const [distributorError, setDistributorError] = useState<string>('');
    const [distributorLoading, setDistributorLoading] = useState<boolean>(false);
    const [statusCode, setStatusCode] = useState();

  const getDistributorInfo = useCallback(() => {
      setDistributorLoading(true);
      setDistributorError('');
      setStatusCode(undefined)
      const scope = Config.GetConfigData().auraWebService?.scopes[0];
      const baseUrl = Config.GetConfigData().serviceUrl;
      const requestUrl = `${baseUrl}api/v1/distributor/me`;
      AuthConfig.getToken(scope).then(
          (success) => {
            const headers = {
              Authorization: `Bearer ${success}`,
            };
            logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
            axios
              .get(requestUrl, { headers })
              .then(
                (response) => {
                  const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                  logger.logInformation(`Get request to ${requestUrl} for distributor details was successful for distributor id: ${response.data.distributorId}: ${status}.`);
                  setDistributorDetails(response.data);
                  setDistributorError('');
                },
  
                (error) => {
                  var status = 'Status Code: N/A';
                  if(error.response && error.response.status ) {
                      status = `Status Code: ${error.response.status}`;
                      setStatusCode(error.response.status)
                  }
                 
                  logger.logError(`Error occured to fetch distributor details for ${requestUrl}: ${status}.`)
                  if(error.message && error.message !== '') {
                    setDistributorError(error.message);
                  } else {
                    setDistributorError(error.response.data);
                  }
                  setDistributorDetails(undefined);
                }
              )
              .finally(() => {
                  setDistributorLoading(false);
              });
          },
          (error) => {
            setDistributorLoading(false);
            setDistributorError(error);
          }
        );
      }
  , []);

  return { getDistributorInfo, distributorDetails, distributorError, distributorLoading, statusCode };
};
