import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { IEditPartnerInfoRequest, UseEditPartnerInfo } from "./UseEditPartnerInfo";
import { useGetDistributorInfoWrapper } from "../useGetDistributorInfo/userGetDistributorInfoWrapper";

export const useEditPartnerInfoWrapper = () => {
    const { editPartnerInfo, reqestResponse, reqestLoading, reqestError } = UseEditPartnerInfo();
    const { setInfobar, setLoader, reset } = usePartnerInfo();
    const {getDistributorInfo, distributorLoading, distributorDetails } = useGetDistributorInfoWrapper();
    const refPartnerInfo = useRef<IEditPartnerInfoRequest>();
    const refDistributorId = useRef<string>();
    useEffect(() => {
        if(reqestError !== '') {
           logger.logError(`Error occurred while editing partner details: ${reqestError}, Request details: ${refPartnerInfo?.current} and partnerId: ${refDistributorId?.current}`)
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])

    useEffect(() => {
       setLoader(reqestLoading || distributorLoading)
    },[reqestLoading, setLoader, distributorLoading])

    useEffect(() => {
     if(reqestResponse) {
        setInfobar({message:'Partner Info edited successfully',messageType: InfoMessageType.success })
        logger.logInformation(`Partner Info edited successfully.Request details: ${refPartnerInfo?.current} and partnerId: ${refDistributorId?.current}`)
        getDistributorInfo();
     }
    },[reqestResponse, setInfobar, getDistributorInfo])

    const editPartnerInfoDetails = useCallback((req: IEditPartnerInfoRequest, partnerId: string) => {
        reset();
        editPartnerInfo(req, partnerId)
        logger.logError(`Edit Partner details initiated successfully. request details: ${req} and partnerId: ${partnerId}`)
        refPartnerInfo.current = req;
        refDistributorId.current = partnerId;
    }, [editPartnerInfo, reset])

    return {editPartnerInfoDetails, reqestResponse, distributorDetails}
} 