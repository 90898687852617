import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { IInviteUserRequest, useUserService } from "./useGetUserService";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";

export const useGetUserServiceWrapper = () => {
    const { addUser, reInviteUser, reqestError, reqestLoading, reqestResponse } = useUserService();
    const { setInfobar, setLoader, reset } = usePartnerInfo();
    const infoMessage = useRef('');
    const { t } = useTranslation();
    const inviteUserReq = useRef<IInviteUserRequest>(
        {
            emailId: '',
            distributorId: ''
        }
    ); 
    useEffect(() => {
        if(reqestError !== '') {
           logger.logError(JSON.stringify(reqestError))
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
       setLoader(reqestLoading)
    },[reqestLoading, setLoader])
    
    useEffect(() => {
       if(reqestResponse) {
        logger.logInformation(infoMessage.current + `EmailId is ${inviteUserReq.current.emailId}, distributorId is ${inviteUserReq.current.distributorId}`)
        setInfobar({message:infoMessage.current, messageType: InfoMessageType.success})
       }
    }, [reqestResponse, setInfobar])


    const inviteUser = useCallback((req: IInviteUserRequest) => {
        reset()
        inviteUserReq.current = req;
        if(req.emailId === '')
        {
            logger.logError(`Email Id is empty to invite user, distributorId is ${req.distributorId}`);
            return;
        }
        addUser(req);
        infoMessage.current = t('UserInvitedSuccessMsg');
    },[addUser, reset, t]) 

    const reinviteUser = useCallback((req: IInviteUserRequest) => {
        reset()
        inviteUserReq.current = req;
        if(req.emailId === ''){
            logger.logError(`Email Id is empty to invite user, distributorId is ${req.distributorId}`);
            return;
        }
        reInviteUser(req);
        infoMessage.current = t('UserReinvitedSuccessMsg');
    },[reInviteUser, reset, t]) 


    return {reqestResponse, reqestError, inviteUser, reinviteUser}
} 