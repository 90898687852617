import { useCallback, useEffect, useRef } from "react";
import { usePartnerInfo } from "../usePartnerInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { useDeviceService } from "./useGetDeviceService";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";

export const useGetDeviceServiceWrapper = () => {
    const { assignToClinic, removefromClinic, reqestError, reqestLoading, reqestResponse } = useDeviceService();
    const { setInfobar, setLoader, reset } = usePartnerInfo();
    const infoMessage = useRef('');
    const clinicid = useRef('');
    const { t } = useTranslation();
    useEffect(() => {
        if(reqestError !== '') {
           logger.logError(JSON.stringify(reqestError))
           setInfobar({message:reqestError,messageType: InfoMessageType.error })
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
       setLoader(reqestLoading)
    },[reqestLoading, setLoader])
    
    useEffect(() => {
       if(reqestResponse) {
        logger.logInformation(infoMessage.current + `Clinic Id is ${clinicid.current}`)
        setInfobar({message:infoMessage.current, messageType: InfoMessageType.success})
       }
    }, [reqestResponse, setInfobar])


    const assignDeviceToClinic = useCallback((deviceId:string, clinicId:string, distributorId:string, serialNumber:string) => {
        reset();
        clinicid.current = clinicId;
        assignToClinic(deviceId,clinicId, distributorId);
        infoMessage.current = t('AssignDeviceSuccessMsg', {serialNumber: serialNumber});
    },[assignToClinic, reset, t]) 

    const removeDevicefromClinic = useCallback((deviceId:string, clinicId:string, distributorId:string, serialNumber:string) => {
        reset();
        clinicid.current = clinicId;
        removefromClinic(deviceId,clinicId, distributorId)
        infoMessage.current = t('UnassignDeviceSuccessMsg', {serialNumber: serialNumber});
    },[removefromClinic, reset, t]) 


    return {reqestResponse, assignDeviceToClinic, removeDevicefromClinic}
} 