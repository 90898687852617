import { useEffect } from "react"
import React from "react";
import UnassignDeviceInstructions from "./UnassignDeviceInstructions";
import { useGetDeviceServiceWrapper } from "src/Utils/Hooks/DeviceService/useGetDeviceServiceWrapper";
import SmallDialog from "src/SharedComponents/SmallDialog/SmallDialog";
import { useTranslation } from "react-i18next";
interface IUnassignDevice {
    deviceId: string;
    clinicId?: string;
    clinicName?: string;
    serialNumber: string;
    distributorId:string, 
    updateDevices: () => void
    closeDialog: () => void;
}


const UnassignDevice: React.FC<IUnassignDevice> = ({deviceId, clinicId, clinicName, serialNumber,distributorId, updateDevices, closeDialog}) => {
    const { reqestResponse, removeDevicefromClinic} = useGetDeviceServiceWrapper();
    const { t } = useTranslation();
    useEffect(() => {
        if(reqestResponse) {
           updateDevices()
        }
       },[reqestResponse, updateDevices])
    return (
        <>
            
                <SmallDialog
                    submitButtonText={t("Ok")}
                    title='UnassigningDevice'
                    onClose={() => { closeDialog() }}
                    onSubmit={() => {
                        if(clinicId){removeDevicefromClinic(deviceId, clinicId, distributorId, serialNumber)}
                    }} >
                    <UnassignDeviceInstructions clinicName={clinicName} serialNumber={serialNumber} />
                </SmallDialog>
           
        </>
    )
}

export default  React.memo(UnassignDevice);