import { useEffect, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useGetRequest } from "../ApiWrappers/useGetRequest";
export interface ITutorialVideos{
  displayName: string;
  sasToken: string;
  previewData: string;
  duration:string
}

export const useGetTutorialVideos = () => {
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
  const scope = Config.GetConfigData().auraWebService?.scopes[0];
  const requestUrl = `${baseUrl}api/v1/downloads/tutorial-videos`;
  const [getDetails, data, loading, error] = useGetRequest<ITutorialVideos[]>()
  useEffect(() => {
    getDetails(scope, requestUrl)
 },[getDetails, requestUrl, scope])
    return { 
      data,
      loading,
      error
   };
}
