import FlushAccordian from "src/SharedComponents/FlushAccordian/FlushAccordian";
import { useGetTutorialVideosWrapper } from "src/Utils/Hooks/Downloads/useGetTutorialVideosWrapper";
import Document from "./../Documents/Document";

const TutorialVideos = () => {
    const { data } = useGetTutorialVideosWrapper()
    return (
        <>
            {data &&
                <>
                    <hr className='line mt-4'></hr>
                    <FlushAccordian title="Tutorial Videos" id='techVideos'>
                        <>
                            <div className="row" style={{ margin: 'auto' }}>
                                {data.map((video, i) => (
                                    <div className="col-md-4 col-sm-12" style={{ padding: '0' }} key={i}>
                                        <div className="m-1">
                                            <Document key={i} image={video.previewData} title={video.displayName} fileLink={video.sasToken} duration={video.duration} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    </FlushAccordian>
                </>

            }
        </>
    )
}
export default TutorialVideos;